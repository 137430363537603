import { LoadingService } from './loading/loading.service';
import { InputUtilService } from './input-util/input-util.service';
import { ParamUtilService } from './param-util/param-util.service';
import { ObjectUtilService } from './object-util/object-util.service';
import { FormDebugComponent } from './form-debug/form-debug.component';
import { MessageGrowlService } from './message-growl/message-growl.service';
import { MessageGrowlComponent } from './message-growl/message-growl.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GrowlModule } from 'primeng/primeng';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { FrameJsfComponent } from './frame-jsf/frame-jsf.component';

@NgModule({
  imports: [CommonModule, GrowlModule],
  declarations: [
    MessageGrowlComponent,
    PageNotFoundComponent,
    // FormDebugComponent,
    FrameJsfComponent,
  ],
  exports: [
    MessageGrowlComponent,
    // FormDebugComponent
  ],
  providers: [MessageGrowlService, ObjectUtilService, ParamUtilService, InputUtilService, LoadingService],
})
export class UtilModule {}
