import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Message } from 'primeng/api';
import { Observable, Subscription } from 'rxjs';
import { EstadoConservacao } from 'src/app/m_estoque/model/estado-conservacao';
import { Inventario } from 'src/app/m_estoque/model/inventario';
import { Labels } from 'src/app/core/constant/labels';
import { GenericEntity } from 'src/app/core/model/generic-entity';
import { LoadingService } from 'src/app/_util/loading/loading.service';
import { MessageGrowlService } from 'src/app/_util/message-growl/message-growl.service';
import { InventarioLoadService } from '../resolver/inventario-load.service';
import { Mensagens } from 'src/app/core/constant/messages';

@Component({
  selector: 'app-inventario-list',
  templateUrl: './inventario-list.component.html',
  styleUrls: ['./inventario-list.component.scss'],
})
export class InventarioListComponent implements OnInit, OnDestroy {
  subscription: Subscription;
  tempMsg: Message[] = [];
  estadosConservacao: EstadoConservacao[] = [];
  situacoes: GenericEntity[];
  inventarios: Inventario[] = [];
  isMobile = false;
  loading: any = {
    on: () => this.loadingService.onLoading(true),
    off: () => this.loadingService.onLoading(false),
  };

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private loadingService: LoadingService,
    public lbs: Labels,
    private msgGrowl: MessageGrowlService,
    private msg: Mensagens
  ) {}

  detectMob() {
    const toMatch = [/Android/i, /webOS/i, /iPhone/i, /iPad/i, /iPod/i, /BlackBerry/i, /Windows Phone/i];

    return toMatch.some((toMatchItem) => {
      let match = navigator.userAgent.match(toMatchItem);
      return match != null && match.length > 0;
    });
  }
  ngOnDestroy() {
    if (this.subscription != undefined) this.subscription.unsubscribe();
  }
  ngOnInit() {
    this.load();
  }
  load() {
    this.isMobile = this.detectMob();
    this.loading.on();
    this.subscription = this.activatedRoute.data.subscribe(
      (next) => {
        this.loading.off();
        this.estadosConservacao = next.loadResolver[InventarioLoadService.ESTADO_CONSERVACAO];
        this.situacoes = next.loadResolver[InventarioLoadService.SITUACOES].retorno;
        this.inventarios = this.ordenarInventarios(next.loadResolver[InventarioLoadService.INVENTARIOS]);
      },
      (error) => {
        if (error.error) error = error.error;
        this.mostrarMensagem(3, error.mensagem || error, true, true);
        this.loading.off();
      },
      () => {
        this.loading.off();
      }
    );
  }
  editar(item: Inventario) {
    this.router.navigate(['edit', item.id], {
      relativeTo: this.activatedRoute.parent,
    });
  }
  ordenarInventarios(inventarios: Inventario[]): Inventario[] {
    inventarios = inventarios.map((i) => {
      return new Inventario(i);
    });
    inventarios.sort((inventarioA, inventarioB) => {
      let ordem = inventarioB.situacao.id - inventarioA.situacao.id;
      if (ordem == 0) {
        return inventarioA.dataCriacao.getTime() - inventarioA.dataCriacao.getTime();
      }
      return ordem;
    });
    return inventarios;
  }
  situacao(id: number) {
    for (let situacao of this.situacoes) {
      if (situacao.id == id) return situacao.nome;
    }
    return '';
  }
  mostrarMensagem(severity: number, detail: string, limpar: boolean, mostrarFixa: boolean): void {
    const _severity: string =
      severity == 0 ? 'success' : severity == 1 ? 'info' : severity == 2 ? 'warn' : 'error';
    const summary: string =
      severity == 0
        ? this.msg.msg_success
        : severity == 1
        ? this.msg.msg_info
        : severity == 2
        ? this.msg.msg_warn
        : this.msg.msg_error;
    if (limpar) this.tempMsg = [];
    if (mostrarFixa)
      this.tempMsg.push({
        severity: _severity,
        summary: summary,
        detail: detail,
      });
    switch (severity) {
      case 0:
        this.msgGrowl.showSuccess(summary, detail);
        break;
      case 1:
        this.msgGrowl.showInfo(summary, detail);
        break;
      case 2:
        this.msgGrowl.showWarn(summary, detail);
        break;
      case 3:
        this.msgGrowl.showError(summary, detail);
        break;
      default:
        this.msgGrowl.showWarn(summary, detail);
        break;
    }
  }
  hasNoInventarioAberto() {
    let SITUACAO_AGUARDANDO_ANALISE = 1,
      SITUACAO_EM_ANALISE = 2;
    if (this.inventarios == null || this.inventarios.length < 1) return true;
    for (let inventario of this.inventarios) {
      if (inventario.situacao.id == SITUACAO_AGUARDANDO_ANALISE || inventario.situacao.id == SITUACAO_EM_ANALISE) {
        return false;
      }
    }
    return true;
  }
}
