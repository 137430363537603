import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InventarioListComponent } from './pages/inventario/inventario-list/inventario-list.component';
import { InventarioEditComponent } from './pages/inventario/inventario-edit/inventario-edit.component';
import { RouterModule } from '@angular/router';
import { TabViewModule } from 'primeng/tabview';
import { MessagesModule } from 'primeng/messages';
import { InputTextModule } from 'primeng/inputtext';
import { TableModule } from 'primeng/table';
import { ToolbarModule } from 'primeng/toolbar';
import { PanelModule } from 'primeng/panel';
import { ConfirmationService, MessageService } from 'primeng/api';
import { BrowserModule } from '@angular/platform-browser';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ReactiveFormsModule } from '@angular/forms';
import { KeyFilterModule } from 'primeng/keyfilter';
import { CalendarModule } from 'primeng/calendar';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { SelectButtonModule } from 'primeng/selectbutton';
import { InputSwitchModule } from 'primeng/inputswitch';
import { FileUploadModule } from 'primeng/fileupload';
import { CheckboxModule } from 'primeng/checkbox';
import { AutoCompleteModule, DialogModule, DropdownModule, MessageModule } from 'primeng/primeng';

@NgModule({
  declarations: [InventarioListComponent, InventarioEditComponent],
  imports: [
    CommonModule,
    //BrowserModule, -- ser importado apenas uma vez no appModule,
    ReactiveFormsModule,
    /*PRIMENG*/
    DialogModule,
    AutoCompleteModule,
    DropdownModule,
    RouterModule,
    ConfirmDialogModule,
    InputTextModule,
    KeyFilterModule,
    MessagesModule,
    MessageModule,
    PanelModule,
    TabViewModule,
    TableModule,
    ToolbarModule,
    CalendarModule,
    InputTextareaModule,
    SelectButtonModule,
    InputSwitchModule,
    CheckboxModule,
    FileUploadModule,
  ],
  providers: [
    /*PRIMENG*/
    ConfirmationService,
    //MessageService
  ],
})
export class PaginasEstoqueModule {}
