import { MessageGrowlService } from '../../_util/message-growl/message-growl.service';
import { UsuarioLogin } from '../domain/usuario-login';
import { HttpErrorResponse } from '@angular/common/http';
import { MenuService } from '../../menu/menu.service';
import { Labels } from '../../core/constant/labels';
import { AuthService } from '../auth.service';
import { Component } from '@angular/core';
import { Mensagens } from 'src/app/core/constant/messages';

@Component({
  selector: 'app-login',
  templateUrl: './app.login.component.html',
})
export class AppLoginComponent {

  /*primeng*/

  dark: boolean = false;

  checked: boolean;

  /*mine*/
  
  usuario: UsuarioLogin = new UsuarioLogin();
  disabled: boolean = false;//VAI DESATIVAR O FORMULÁRIO DURANTE O LOGIN, PARA O USUÁRIO NÃO FICAR CLICANDO

  constructor(
    private AuthService: AuthService, 
    private messageGrowlService: MessageGrowlService, 
    private msg: Mensagens, 
    private menuService: MenuService, 
    //public app:AppComponent,
    public lbs:Labels
  ) { }

  ngOnInit() {
    try{
      let hash = (window.location.hash);
      let query = hash.split('?')[1];
      let params:any[]= query.split("&");
      params = params.map((a)=>{let p=a.split('='); let obj = ({}); obj[p[0]]=p[1]; return obj });
      let obj:any={};
      for(let param of params){
        obj={...obj,...param};
      }
      this.usuario.$login=obj.usuario;
      this.usuario.$senha=obj.senha;
    }catch(e){
      //se der erro não afeta o codigo
    }
  }

  onLogin(){
    /**VAI REALIZAR O LOGIN VIA REST, PASSANDO A AUTENTICAÇÃO FAZ LOGIN DO JSF PARA TER UMA SESSÃO NO JSF - SOLUÇÃO TEMPORÁRIA*/
    this.disabled = true;
    //this.app.block();
    this.AuthService.login(this.usuario).subscribe(//para chamadas HTTP é utilizado subscribe
      data => {
        //this.loginJSF();
      },
      (error: HttpErrorResponse) => {
        //this.app.unblock();
        this.disabled = false;
        if(error.status == 404)
            this.messageGrowlService.showWarn(this.msg.msg_warn, this.lbs.usuario.loginFalhaComunicacao);
        else if(error.status == 401)
          this.messageGrowlService.showWarn(this.msg.msg_warn, error.error.mensagem);    
        else{                  
          this.messageGrowlService.showWarn(this.msg.msg_warn, (typeof error.error === 'string' ? JSON.parse(error.error).mensagem : this.lbs.usuario.loginUnknownErro));
        }
      },
      () => {
        //this.app.unblock();
        this.disabled = false;
      }
    );

  }

  loginJSF(){
    this.disabled = true;
    this.AuthService.loginJSF(this.usuario).subscribe(//método temporário para login no JSF
      data => {
      },
      error => {
        this.disabled = false;
      },
      () => {
        this.disabled = false;
      }
    );
  }

}
