import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { Path } from 'src/app/core/constant/path';
import { ParamUtilService } from 'src/app/_util/param-util/param-util.service';
import { RetornoResponse } from 'src/app/core/model/retorno-response';
const PATH = Path.IND + 'pessoa/';

const URL_LOAD_POR_CPF = PATH + 'load-por-cpf';
@Injectable({
  providedIn: 'root',
})
export class PessoaHttpService {
  constructor(private httpClient: HttpClient, protected paramUtil: ParamUtilService) {}

  loadPorCPF(cpf: string) {
    const p = this.paramUtil.jsonToParams({ cpf: cpf });
    return this.httpClient.get<RetornoResponse>(URL_LOAD_POR_CPF, { params: p }).pipe(
      map((res) => {
        if (res.codigo == 1) throw res.mensagem;
        return res.retorno;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }
}
