import { RetornoResponse } from 'src/app/core/model/retorno-response';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ParamUtilService } from 'src/app/_util/param-util/param-util.service';
import { map, catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { Path } from 'src/app/core/constant/path';

@Injectable({
  providedIn: 'root',
})
export class AlterarEmailService {
  constructor(private httpClient: HttpClient) {}

  load() {
    return this.httpClient.get<RetornoResponse>(Path.ACS_USUARIO_USUARIOEMAIL).pipe(
      map((res) => {
        if (res.codigo == 1) throw res.mensagem;
        return res.retorno;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  atualizarEmail(emailNotificacao: string) {
    return this.httpClient.put<RetornoResponse>(Path.ACS_USUARIO_USUARIOEMAIL, { emailNotificacao }).pipe(
      map((res) => {
        if (res.codigo == 1) throw res.mensagem;
        return res.retorno;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }
}
