import { MessageGrowlService } from '../../_util/message-growl/message-growl.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Labels } from '../../core/constant/labels';
import { AuthService } from '../auth.service';
import { Component, OnInit } from '@angular/core';
import { Mensagens } from 'src/app/core/constant/messages';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-alterar-senha',
  templateUrl: './alterar-senha.component.html',
})
export class AlterarSenhaComponent implements OnInit {
  token: string;
  novaSenha: string;
  novaSenhaConfirmacao: string;
  disabled: boolean = false; //VAI DESATIVAR O FORMULÁRIO DURANTE O LOGIN, PARA O USUÁRIO NÃO FICAR CLICANDO
  subscription: Subscription;
  form!: FormGroup;

  constructor(
    private authService: AuthService,
    private messageGrowlService: MessageGrowlService,
    private msg: Mensagens,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    public lbs: Labels
  ) {}

  ngOnInit() {
    this.subscription = this.activatedRoute.params.subscribe((next) => {
      this.token = next['token'];
    });
    this.carregarForm();
  }

  carregarForm() {
    this.form = this.formBuilder.group({
      novaSenha: [{ value: null, disabled: this.disabled }, [Validators.required], this.disabled],
      novaSenhaConfirmacao: [{ value: null, disabled: this.disabled }, [Validators.required], this.disabled],
    });
  }

  onAtualizarSenha() {
    if (this.form.valid) {
      this.novaSenha = this.form.get('novaSenha').value;
      this.novaSenhaConfirmacao = this.form.get('novaSenhaConfirmacao').value;

      if (this.novaSenha !== this.novaSenhaConfirmacao) {
        Object.keys(this.form.controls).forEach((e) => {
          this.form.controls[e].markAsDirty();
        });
        this.messageGrowlService.showWarn(
          this.msg.msg_warn,
          'As senhas informadas não coincidem, favor preencha a mesma senha nos dois campos'
        );
        return;
      }
      this.disabled = true;
      this.authService.alterarSenha(this.novaSenha, this.token).subscribe(
        (retorno) => {
          this.messageGrowlService.showSuccess(this.msg.msg_warn, retorno.mensagem);
          this.router.navigate(['/login']);
        },
        (error: HttpErrorResponse) => {
          const message = error.error.mensagem || 'Não foi possível redefinir senha.';
          this.disabled = false;
          this.messageGrowlService.showWarn(this.msg.msg_warn, message);
        },
        () => {
          this.disabled = false;
        }
      );
    } else {
      Object.keys(this.form.controls).forEach((e) => {
        if (this.form.controls[e].invalid) {
          /**vai setar o campo como ng-dirty e como ja sera ng-invalid aplicara o css do template primeng invalido ng-dirty ng-invalid*/
          this.form.controls[e].markAsDirty();
        }
      });
      this.messageGrowlService.showWarn(this.msg.msg_warn, this.lbs.m.formVazio);
    }
  }
}
