import { ObjectUtil } from '../../../../util/service/object-util.service';
import { CandidatoVagaHttpService } from 'src/app/core/modulos/rh/http/candidato-vaga-http.service';
import { Observable, forkJoin } from 'rxjs';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { GrauInstrucaoHttpService } from 'src/app/core/modulos/tabela/http/grau-instrucao-http.service';
import { GrauParentescoHttpService } from 'src/app/core/modulos/tabela/http/grau-parentesco-http.service';

@Injectable()
export class DependentesResolverService implements Resolve<any> {
  public static readonly GRAUINSTRUCAO = 0;
  public static readonly GRAUPARENTESCO = 1;
  public static readonly CANDIDATOSESSAO = 2;
  public static readonly DEPENDENTECANDIDATO = 3;

  constructor(
    private candidatoVagaHttpService: CandidatoVagaHttpService,
    private grauInstrucaoHttpService: GrauInstrucaoHttpService,
    private grauParentescoHttpService: GrauParentescoHttpService
  ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    //this.loadingService.on();
    //this.loadingService.onLoading(true);
    let corrigir: boolean = ObjectUtil.toBoolean(route.paramMap.get('corrigir'));
    let loadPile: Observable<any>[] = [];
    loadPile.push(this.grauInstrucaoHttpService.listarGenericOptions()); //0
    loadPile.push(this.grauParentescoHttpService.listarGenericOptions()); //1
    if (corrigir) loadPile.push(this.candidatoVagaHttpService.loadDadosCadastrais()); //2
    else loadPile.push(this.candidatoVagaHttpService.loadCandidatoAtivo()); //2
    const idCandidado = Number.parseInt(sessionStorage.getItem('idCandidato'));
    loadPile.push(this.candidatoVagaHttpService.listarDependentesCandidato(idCandidado)); //1
    return forkJoin(loadPile);
  }
}
