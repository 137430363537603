import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { FormGroup, FormBuilder, Validators, FormControl, AbstractControl } from '@angular/forms';
import { Message, ConfirmationService } from 'primeng/api';
import { Router, ActivatedRoute } from '@angular/router';
import { Labels } from 'src/app/core/constant/labels';
import { MessageGrowlService } from 'src/app/_util/message-growl/message-growl.service';
import { SelectItem } from 'primeng/api';

import { InputUtilService } from 'src/app/_util/input-util/input-util.service';
import { LoadingService } from 'src/app/_util/loading/loading.service';
import { LancamentoService } from 'src/app/m_rh/services/lancamento.service';
import { Lancamento } from 'src/app/m_rh/model/lancamento';
import { Mensagens } from 'src/app/core/constant/messages';
import { RetornoResponse } from 'src/app/core/model/retorno-response';

@Component({
  selector: 'app-lancamento-edit',
  templateUrl: './lancamento-edit.component.html',
  styleUrls: ['./lancamento-edit.component.scss'],
})
export class LancamentoEditComponent implements OnInit {
  @ViewChild('botaoFoto', { static: false }) botao: HTMLButtonElement;

  checked1: boolean = false;

  subscription: Subscription; //boa prática//aplicar em todos inscricoes, e fazer de uma forma mais facil com decorator como eh sugerido na WEB
  form: FormGroup;
  id: number;
  bean: Lancamento = new Lancamento(null);
  tiposLancamento: SelectItem[];
  tituloSalvar: string;
  observacaoSupervisor: string = '';

  //optionsCentroCusto: SelectItem[];

  tempMsg: Message[] = [];

  //FORM
  classStyleColumn: string = 'p-col-12 p-xl-6 p-lg-6 p-md-6 p-sm-12 my-classStyleColumn p-nogutter';
  classStyleRow: string = 'p-col-12 p-grid';
  classStyleLabel: string = 'p-col-fixed my-classStyleLabel'; //'p-col-4 p-xl-4 p-lg-4 p-md-4 p-sm-12';//colunas com tamanhos não fixo (DEFAULT).
  classStyleField: string = 'p-col'; //'p-col-8 p-xl-8 p-lg-8 p-md-8 p-sm-12';//colunas com tamanhos não fixo (DEFAULT).

  constructor(
    private confirmationService: ConfirmationService,
    private router: Router,
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    //private location: Location,
    //public app: AppComponent,
    public lbs: Labels,
    private msgGrowl: MessageGrowlService,
    private msg: Mensagens,
    private inputUtilService: InputUtilService,
    private loadingService: LoadingService,
    private lancamentoService: LancamentoService //private centroCustoService: CentroCustoService
  ) {
    console.log('constructor.edit');
    this.tiposLancamento = [
      { label: 'Hora Extra', value: 1 },
      { label: 'Sobre Aviso', value: 3 },
      { label: 'Banco de Horas', value: 2 },
    ];

    this.tituloSalvar = lbs.lancamento.salvar;

    this.getLocation();
  }

  getLocation() {
    if (navigator) {
      navigator.geolocation.getCurrentPosition((pos) => {
        this.bean.longitude = +pos.coords.longitude;
        this.bean.latitude = +pos.coords.latitude;
      });
    }
  }

  /*NG*/
  ngOnInit() {
    console.log('ngOnInit.edit');
    this.carregarForm();
    this.editar();
    //this.listarOptionsCentroCusto();
  }

  ngOnDestroy() {
    if (this.subscription != null) this.subscription.unsubscribe; //boa prática
  }

  /**/

  onFotoSelecionada_BKP(event) {
    let file = event.target.files[0];
    console.log(file);
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.bean.foto = reader.result;
      this.salvar();
    };
  }

  onFotoSelecionada(event) {
    let file = event.target.files[0];
    console.log(file);
    let reader = new FileReader();
    reader.onloadend = () => {
      this.resizeImage(file, 800, 800);
    };
    reader.readAsDataURL(file);
  }

  resizeImage(file: File, maxWidth: number, maxHeight: number) {
    let image = new Image();
    image.src = URL.createObjectURL(file);
    image.onload = () => {
      let width = image.width;
      let height = image.height;

      if (width <= maxWidth && height <= maxHeight) {
        this.mostrarMensagem(3, 'Imagem muito pequena', false, false);
        return file;
      }

      let newWidth;
      let newHeight;

      if (width > height) {
        newHeight = height * (maxWidth / width);
        newWidth = maxWidth;
      } else {
        newWidth = width * (maxHeight / height);
        newHeight = maxHeight;
      }

      let canvas = document.createElement('canvas');
      canvas.width = newWidth;
      canvas.height = newHeight;

      let context = canvas.getContext('2d');

      context.drawImage(image, 0, 0, newWidth, newHeight);
      var finalFile = canvas.toDataURL(file.type);
      let reader2 = new FileReader();
      canvas.toBlob(function (blob) {
        reader2.readAsDataURL(blob);
      }, file.type);

      reader2.onloadend = () => {
        this.bean.foto = reader2.result;
        this.salvar();
      };
    };
  }

  validarBean(): boolean {
    //pristine é o contrário do dirty
    let valido: boolean = true;
    /**Verificar se os campos obrigatorios estao preenchidos */

    Object.keys(this.form.controls).forEach((e) => {
      if (this.form.controls[e].invalid) {
        /**vai setar o campo como ng-dirty e como ja sera ng-invalid aplicara o css do template primeng invalido ng-dirty ng-invalid*/
        this.form.controls[e].markAsDirty();
        this.form.controls[e].markAllAsTouched();
        valido = false;
      }
    });

    /**Ao final verifica se após verificações formulário e dados estão válidos*/
    if (!valido || !this.form.valid) {
      valido = false;
      this.mostrarMensagem(2, this.msg.msg_form_vazio, true, true);
    }
    if (valido) {
      if (this.router.url.includes('/edit')) {
        this.salvar();
      } else {
        if (this.bean.foto == undefined) {
          document.getElementById('botaoFoto').click();
        } else {
          this.salvar();
        }
      }
    }
    return valido;
  }

  salvar(): void {
    // if(this.validarBean()){
    //this.app.block();
    this.loadingService.onLoading(true);

    this.lancamentoService.salvar(this.bean).subscribe(
      (next: RetornoResponse) => {
        if (this.bean.id == null) {
          this.bean.id = next.retorno.id;
        }
        this.tituloSalvar = 'Salvar';
        this.mostrarMensagem(0, next.mensagem, true, false);
        this.loadingService.onLoading(false);
      },
      (error) => {
        this.mostrarMensagem(2, error, true, true);
        //this.app.unblock();
        this.loadingService.onLoading(false);
      },
      () => {
        //this.app.unblock();
        this.loadingService.onLoading(false);
      }
    );

    // }
  }

  editar(): void {
    if (this.router.url.includes('/edit')) {
      this.tituloSalvar = this.lbs.b.salvar;
      /**LOAD É FEITO ATRAVÉS DO RESOLVER QUE É CARREGADO ANTES DO COMPONENTE SE INICIALIZADO */
      this.subscription = this.activatedRoute.data.subscribe((data: { editResolver: any }) => {
        if (data.editResolver != null) {
          console.log(data);
          this.bean = data.editResolver;
          this.bean.entrada = new Date(this.toISOFormat(this.bean.entradaString));
          this.bean.saida = new Date(this.toISOFormat(this.bean.saidaString));
          if (this.bean.observacaoSupervisor1 != null) {
            this.observacaoSupervisor = this.bean.observacaoSupervisor1;
          }
          if (this.bean.observacaoSupervisor2 != null) {
            this.observacaoSupervisor = this.observacaoSupervisor + this.bean.observacaoSupervisor2;
          }
          //this.bean.tipoLancamentoEntrada = data.editResolver.tipoLancamentoEntrada.id;
        } else {
          this.limpar();
          this.mostrarMensagem(2, `${this.msg.msg_registro_naoexiste} ${this.id}.`, true, true);
        }
      });
    }
  }

  toISOFormat(dateTimeString) {
    // Primeiro, dividimos a data completa em duas partes:
    const [date, time] = dateTimeString.split(' ');

    // Dividimos a data em dia, mês e ano:
    const [DD, MM, YYYY] = date.split('/');

    // Dividimos o tempo em hora e minutos:
    const [HH, mm] = time.split(':');

    // Retornamos a data formatada em um padrão compatível com ISO:
    return `${YYYY}-${MM}-${DD}T${HH}:${mm}`;
  }

  load(id: any): void {
    //this.app.block();
    this.lancamentoService.load(id).subscribe(
      (next) => {
        if (next != null) {
          console.log('load: 00');
          this.bean = next.gateway;
          if (this.bean.observacaoSupervisor1 != null || this.bean.observacaoSupervisor2 != null) {
            console.log('load: 01');
            this.observacaoSupervisor =
              "<BR><font color='red'>" +
              this.bean.observacaoSupervisor1 +
              this.bean.observacaoSupervisor2 +
              '</font>';
          }
          console.log('load: 02');
        } else {
          this.limpar();
          this.mostrarMensagem(2, `${this.msg.msg_registro_naoexiste} ${this.id}.`, true, true);
        }
      },
      (error) => {
        this.mostrarMensagem(3, error, true, true);
        //this.app.unblock();
      },
      () => {
        //this.app.unblock();
      }
    );
  }

  excluir(): void {
    if (this.bean.id != null) {
      console.log('excluir: 01 ' + this.bean.id);
      this.confirmationService.confirm({
        message: this.lbs.m.confirmarExcluir,
        accept: () => {
          //this.app.block();
          console.log('excluir: 0 ' + this.bean.id);
          this.lancamentoService.excluir(this.bean.id).subscribe(
            (next) => {
              console.log('excluir: 1 ' + this.bean.id);
              this.mostrarMensagem(0, next.mensagem, true, false);
              this.limpar();
            },
            (error) => {
              console.log('excluir: 2 ' + this.bean.id);
              this.mostrarMensagem(3, error, true, true);
              //this.app.unblock();
            },
            () => {
              console.log('excluir: 3 ' + this.bean.id);
              //this.app.unblock();
            }
          );
        },
      });
    }
  }

  voltar() {
    history.back();
  }

  limpar(): void {
    this.bean = new Lancamento(null);
    /**Limpar o status dos controls no form para limpar o CSS de campos obrigatorios */
    Object.keys(this.form.controls).forEach((e) => {
      this.form.controls[e].markAsPristine();
      this.form.controls[e].markAsUntouched();
    });
  }

  novo(): void {
    this.limpar(); /**no caso da rota NEW já ter sido carregada.*/
    this.router.navigate(['/beacon/gateway/new']);
  }

  /*OUTROS*/

  /**volta para página de listagem */

  private carregarForm(): void {
    this.form = this.formBuilder.group({
      id: [null],
      tipoLancamentoEntrada: this.formBuilder.group({
        id: [null, [Validators.required, Validators.maxLength(2), Validators.min(1)]],
      }),
      entrada: [null, [Validators.required, Validators.maxLength(20)]],
      saida: [null, [Validators.required, Validators.maxLength(20)]],
      feriado: [null],
      observacaoFuncionario: [null, [Validators.required, Validators.maxLength(250)]],
    });
  }

  onBasicUpload(event) {
    //this.messageService.add({severity: 'info', summary: 'Success', detail: 'File Uploaded with Basic Mode'});
    console.log('teste ok onBasicUpload');
  }
  /*OPTIONS*/

  //listarOptionsCentroCusto(): void {
  //this.centroCustoService.listarOptions().subscribe(
  //(next: any[]) => this.optionsCentroCusto = this.inputUtilService.toSelectItem(next, 'nome')
  //);
  //}

  /*MESSAGES*/

  /**0-sucess, 1-info, 2-warn, 3-error*/
  mostrarMensagem(severity: number, detail: string, limpar: boolean, mostrarFixa: boolean): void {
    const _severity: string =
      severity == 0 ? 'success' : severity == 1 ? 'info' : severity == 2 ? 'warn' : 'error';
    const summary: string =
      severity == 0
        ? this.msg.msg_success
        : severity == 1
        ? this.msg.msg_info
        : severity == 2
        ? this.msg.msg_warn
        : this.msg.msg_error;
    if (limpar) this.tempMsg = [];
    if (mostrarFixa) this.tempMsg.push({ severity: _severity, summary: summary, detail: detail });
    switch (severity) {
      case 0:
        this.msgGrowl.showSuccess(summary, detail);
        break;
      case 1:
        this.msgGrowl.showInfo(summary, detail);
        break;
      case 2:
        this.msgGrowl.showWarn(summary, detail);
        break;
      case 3:
        this.msgGrowl.showError(summary, detail);
        break;
      default:
        this.msgGrowl.showWarn(summary, detail);
        break;
    }
  }

  mostrarRequired(nomeControl: string, subgrupo?: string) {
    /**SE CAMPO NÃO FOR VÁLIDO E FOI VISITADO VAI MOSTRAR MENSAGEM DE CAMPO OBRIGATÓRIO*/
    /**como o form vai ser carregado depois de verificar qual o tipo da movimentação, fez-se necessário fazer esse if */
    if (this.form == null) return false;
    let controle: AbstractControl = null;
    if (subgrupo != null && subgrupo.trim().length > 1) {
      controle = this.form.get(subgrupo).get(nomeControl);
    } else {
      controle = this.form.get(nomeControl);
    }
    return !controle.valid && controle.touched;
  }
}
