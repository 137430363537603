import { ObjectUtil } from './../../../../util/service/object-util.service';
import { LoadingService } from 'src/app/_util/loading/loading.service';
import { CandidatoVagaHttpService } from 'src/app/core/modulos/rh/http/candidato-vaga-http.service';
import { Observable, forkJoin } from 'rxjs';
import { PaisHttpService } from 'src/app/core/modulos/tabela/http/pais-http.service';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { PreCadastroModule } from 'src/app/m_rh/pages/pre-cadastro/pre-cadastro.module';
import { Injectable } from '@angular/core';
import { RacaCorHttpService } from 'src/app/core/modulos/indice/http/raca-cor-http.service';
import { GrauInstrucaoHttpService } from 'src/app/core/modulos/tabela/http/grau-instrucao-http.service';
import { TipoPcdHttpService } from 'src/app/core/modulos/indice/http/tipo-pcd-http.service';
import { EstadoHttpService } from 'src/app/core/modulos/tabela/http/estado-http.service';
import { BancoHttpService } from 'src/app/core/modulos/tabela/http/banco-http.service';
import { TipoContaBancoHttpService } from 'src/app/core/modulos/tabela/http/tipo-conta-banco-http.service';
import { EstadoCivilHttpService } from 'src/app/core/modulos/indice/http/estado-civil-http.service';
import { TipoLogradouroHttpService } from 'src/app/core/modulos/tabela/http/tipo-logradouro-http.service';
import { on } from 'process';

@Injectable()
export class DadosCadastraisResolverService implements Resolve<any> {
  public static readonly PAIS = 0;
  public static readonly RACACOR = 1;
  public static readonly GRAUINSTRUCAO = 2;
  public static readonly TIPOPCD = 3;
  public static readonly BANCO = 4;
  public static readonly TIPOCONTABANCO = 5;
  public static readonly ESTADO = 6;
  public static readonly ESTADOCIVIL = 7;
  public static readonly TIPOLOGRADOURO = 8;
  public static readonly CANDIDATOSESSAO = 9;

  constructor(
    private loadingService: LoadingService,
    private bancoHttpService: BancoHttpService,
    private candidatoVagaHttpService: CandidatoVagaHttpService,
    private estadoHttpService: EstadoHttpService,
    private estadoCivilHttpService: EstadoCivilHttpService,
    private grauInstrucaoHttpService: GrauInstrucaoHttpService,
    private paisHttpService: PaisHttpService,
    private racaCorHttpService: RacaCorHttpService,
    private tipoPcdHttpService: TipoPcdHttpService,
    private tipoContaBancoHttpService: TipoContaBancoHttpService,
    private tipoLogradouroHttpService: TipoLogradouroHttpService
  ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    //this.loadingService.on();
    //this.loadingService.onLoading(true);
    let corrigir: boolean = ObjectUtil.toBoolean(route.paramMap.get('corrigir'));
    let loadPile: Observable<any>[] = [];
    loadPile.push(this.paisHttpService.listarGenericOptions()); //0
    loadPile.push(this.racaCorHttpService.listarGenericOptions()); //1
    loadPile.push(this.grauInstrucaoHttpService.listarGenericOptions()); //2
    loadPile.push(this.tipoPcdHttpService.listarGenericOptions()); //3
    loadPile.push(this.bancoHttpService.listarGenericOptions()); //4
    loadPile.push(this.tipoContaBancoHttpService.listarGenericOptions()); //5
    loadPile.push(this.estadoHttpService.listarGenericOptions()); //6
    loadPile.push(this.estadoCivilHttpService.listarGenericOptions()); //7
    loadPile.push(this.tipoLogradouroHttpService.listarGenericOptions()); //8
    if (corrigir) loadPile.push(this.candidatoVagaHttpService.loadDadosCadastrais()); //9
    else loadPile.push(this.candidatoVagaHttpService.loadCandidatoAtivo()); //9
    return forkJoin(loadPile);
  }
}
