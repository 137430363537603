import { Injectable } from '@angular/core';
import { Router, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AlterarEmailService } from 'src/app/m_rh/services/alterar-email.service';

@Injectable({
  providedIn: 'root',
})
export class AlterarEmailResolverService {
  constructor(private alterarEmailService: AlterarEmailService, private router: Router) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any | Observable<any> | Promise<any> {
    return this.alterarEmailService.load();
  }
}
