import { LoadingService } from './../_util/loading/loading.service';
import { ObjectUtilService } from './../_util/object-util/object-util.service';
import { AuthService } from './auth.service';
import { Injectable, Injector } from '@angular/core';
import {HttpResponse, HttpEvent,  HttpRequest,   HttpHandler,    HttpInterceptor} from '@angular/common/http';
import { Observable } from 'rxjs'; 
import { throwError } from 'rxjs';
import { map, catchError, finalize } from 'rxjs/operators';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {


    constructor(private injector: Injector, private loadingService:LoadingService){}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const authService = this.injector.get(AuthService);//não consegui instaciar o service dentro do construtor estava dando erro, por causa disso utilizei o Injector.
        //mostrar o modal de loading
        //this.loadingService.onLoading(true);////////
        //não vai interceptar a página de login
        /*REQUEST INTERCEPTOR*/
        if(!req.url.includes('/login') && authService.loggedIn()){
            req = req.clone({
                setHeaders:{
                    authorization: 'Bearer ' + localStorage.getItem('token')
                }
            });
        }
        return next.handle(req)
        /*RESPONSE INTERCEPTOR*/
        .pipe(
            map((event: HttpEvent<any>) => {
            if (event instanceof HttpResponse) {
                if(ObjectUtilService.isNotBlank(event.headers.get('Authorization'))){
                    let token = event.headers.get('Authorization').replace('Bearer', '').trim();
                    localStorage.setItem('token', token);
                }
                // do stuff with response if you want
            }
            return event;
            }),
            catchError(error => {
                return throwError(error);
            }),
            finalize( () => {
                //this.loadingService.onLoading(false);///////////
            })
	    );	
    }
}
