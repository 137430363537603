import { GenericEntity } from 'src/app/core/model/generic-entity';
import { ItemInventario } from './item-inventario';

export class Inventario {
  id: number;
  situacao: { id: number };
  funcionario: { id: number };
  aberto: boolean = true;
  fechado: boolean = false;
  dataCriacao: Date;
  dataAtualizacao: Date;
  dataConclusao: Date;
  //para salvamento local se vir a ser necessário.
  _idLocal: number;
  _itens: ItemInventario[];
  constructor(bean?: any) {
    if (bean != null) {
      for (let key of Object.keys(bean)) {
        if (key.startsWith('data')) {
          if (bean[key] != null) this[key] = new Date(bean[key]);
        } else {
          if (bean[key] != null) this[key] = bean[key];
        }
      }
    }
  }
}
