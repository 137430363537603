import { MessageInputComponent } from 'src/app/util/components/message-input/message-input.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MessageModule } from 'primeng/message';

@NgModule({
  declarations: [MessageInputComponent],
  exports: [MessageInputComponent],
  imports: [CommonModule, MessageModule],
})
export class MessageInputModule {}
