import { GenericOption } from 'src/app/core/model/generic-option';
import { SelectItem } from 'primeng/primeng';

export class PropriedadesTabela {
  public static readonly SEXO: GenericOption[] = [
    { label: 'Masculino', id: 1, value: 1 },
    { label: 'Feminino', id: 2, value: 2 },
  ];
  public static readonly SIMNAO: SelectItem[] = [
    { label: 'Sim', value: true },
    { label: 'Não', value: false },
  ];
  public static readonly BENEFICIOS: GenericOption[] = [
    { label: 'Vale Refeição', id: 2, value: 2 },
    { label: 'Vale Alimentação', id: 1, value: 1 },
  ];
}
