export class DependenteCandidatoEdit {
  idDependenteCandidato: number;
  idDocumentoDependente: number;
  dependente: any;
  candidatoVaga: any;
  aprovado: boolean;
  motivoReprovacao: string;
  tipoDocumento: any;
  numero: string;
  estadoEmissao: string;
  orgaoEmissor: string;
  grauParentesco: any;
  dataNascimento: any;
  cpfCnpj: string;
  nome: string;
  nomeMae: string;
  grauInstrucao: any;
  dataEmissao: any;
  anexo: any;
  anexoVerso: any;
  anexoNome: any;
  anexoVersoNome: any;
  constructor() {}
}
