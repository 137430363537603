import { Labels } from './core/constant/labels';
import { MenuService } from './menu/menu.service';
import { AplicativoMenu } from './menu/domain/aplicativo-menu';
import { ObjectUtilService } from './_util/object-util/object-util.service';
import { ObjetoMenu } from './menu/domain/objeto-menu';
import {Component, Input, OnInit, AfterViewInit, EventEmitter, ViewChild} from '@angular/core';
import {trigger, state, style, transition, animate} from '@angular/animations';
import {MenuItem} from 'primeng/api';
import {AppMainComponent} from './app.main.component';

@Component({
    selector: 'app-menu',
    templateUrl: './app.menu.component.html'
})
export class AppMenuComponent implements OnInit {

    @Input() reset: boolean;

    model: any[];

    constructor(public app: AppMainComponent,
        /**MY IMPORTS */
        private menuService: MenuService,
        private lbs: Labels
        ) {}

    ngOnInit() {
        this.listarMenu();
    }
    bkp_ngOnInit() {
        this.model = [
            {label: 'Dashboard', icon: 'pi pi-fw pi-home', routerLink: ['/']},
            {
                label: 'Components', icon: 'pi pi-fw pi-star',
                items: [
                    { label: 'Sample Page', icon: 'pi pi-fw pi-th-large', routerLink: ['/sample']  },
                    { label: 'Forms', icon: 'pi pi-fw pi-file', routerLink: ['/forms'] },
                    { label: 'Data', icon: 'pi pi-fw pi-table', routerLink: ['/data'] },
                    { label: 'Panels', icon: 'pi pi-fw pi-list', routerLink: ['/panels'] },
                    { label: 'Overlays', icon: 'pi pi-fw pi-clone', routerLink: ['/overlays'] },
                    { label: 'Menus', icon: 'pi pi-fw pi-plus', routerLink: ['/menus'] },
                    { label: 'Messages', icon: 'pi pi-fw pi-envelope', routerLink: ['/messages'] },
                    { label: 'Charts', icon: 'pi pi-fw pi-chart-bar', routerLink: ['/charts'] },
                    { label: 'File', icon: 'pi pi-fw pi-upload', routerLink: ['/file'] },
                    { label: 'Misc', icon: 'pi pi-fw pi-spinner', routerLink: ['/misc'] }
                ]
            },
            {
                label: 'Pages', icon: 'pi pi-fw pi-copy',
                items: [
                    { label: 'Empty', icon: 'pi pi-fw pi-clone', routerLink: ['/empty'] },
                    { label: 'Login', icon: 'pi pi-fw pi-sign-in', routerLink: ['/login'], target: '_blank' },
                    { label: 'Landing', icon: 'pi pi-fw pi-globe', url: 'assets/pages/landing.html', target: '_blank' },
                    { label: 'Error', icon: 'pi pi-fw pi-exclamation-triangle', routerLink: ['/error'], target: '_blank' },
                    { label: '404', icon: 'pi pi-fw pi-times', routerLink: ['/404'], target: '_blank' },
                    {
                        label: 'Access Denied', icon: 'pi pi-fw pi-ban',
                        routerLink: ['/accessdenied'], target: '_blank'
                    }
                ]
            },
            {
                label: 'Hierarchy', icon: 'pi pi-fw pi-sitemap',
                items: [
                    {
                        label: 'Submenu 1', icon: 'pi pi-fw pi-sign-in',
                        items: [
                            {
                                label: 'Submenu 1.1', icon: 'pi pi-fw pi-sign-in',
                                items: [
                                    { label: 'Submenu 1.1.1', icon: 'pi pi-fw pi-sign-in' },
                                    { label: 'Submenu 1.1.2', icon: 'pi pi-fw pi-sign-in' },
                                    { label: 'Submenu 1.1.3', icon: 'pi pi-fw pi-sign-in' },
                                ]
                            },
                            {
                                label: 'Submenu 1.2', icon: 'pi pi-fw pi-sign-in',
                                items: [
                                    { label: 'Submenu 1.2.1', icon: 'pi pi-fw pi-sign-in' }
                                ]
                            },
                        ]
                    },
                    {
                        label: 'Submenu 2', icon: 'pi pi-fw pi-sign-in',
                        items: [
                            {
                                label: 'Submenu 2.1', icon: 'pi pi-fw pi-sign-in',
                                items: [
                                    { label: 'Submenu 2.1.1', icon: 'pi pi-fw pi-sign-in' },
                                    { label: 'Submenu 2.1.2', icon: 'pi pi-fw pi-sign-in' },
                                ]
                            },
                            {
                                label: 'Submenu 2.2', icon: 'pi pi-fw pi-sign-in',
                                items: [
                                    { label: 'Submenu 2.2.1', icon: 'pi pi-fw pi-sign-in' },
                                ]
                            },
                        ]
                    }
                ]
            },
            {
                label: 'Docs', icon: 'pi pi-fw pi-file', routerLink: ['/documentation']
            },
            {
                label: 'Buy Now', icon: 'pi pi-fw pi-money-bill', url: ['https://www.primefaces.org/store']
            }
        ];
    }
    
    /**MY FUNCTION */

    aplicativos: AplicativoMenu[];

    listarMenu(){
        //this.app.block();
        //console.log(localStorage.getItem('idUnidadeAtiva'));
        let idUnidadeAtiva = localStorage.getItem('idUnidadeAtiva');
        this.menuService.listarMenu(idUnidadeAtiva).subscribe(
            data => {
                this.aplicativos = data;
                this.model = [];
                for(let aplicativo of this.aplicativos){
                    this.montarMenu(aplicativo);
                }
            },
            error => {

            },
            () => {
                //this.app.unblock();
        });
    }

    montarMenu2(aplicativo: AplicativoMenu): void{
        //this.model = [];
        let itens: MenuItem[] = new Array();
        for(let menu of aplicativo.menu){
            //INICIAR UM LOOP NO MENU PRINCIPAL, E POSTERIORMENTE VARRER TODA A ARVORE DESSE MENU PRINCIPAL, QUANDO TERMINAR A ÁRVORE DESSE MENU IR PARA O PRÓXIMO MENU PRINCIPAL
            //{label: 'Dashboard', icon: 'fa fa-fw fa-home', routerLink: ['/']},
            let menuItem: MenuItem = {};
            menuItem.label = menu.nomeObjeto;
            menuItem.icon = this.definirIcone(menu);
            if(ObjectUtilService.isNotBlank(menu.url)) menuItem.routerLink = [menu.url];
            /*SE A URL FOR DO TIPO .JSP OU .JSF VAI CARREGAR A PÁGINA DENTRO DA ROUTE /jsf QUE RECEBE COMO PARAMETRO A URL DESSE MENUITEM*/
            if(menu.url != '' && menu.url.indexOf('.js') != -1){
                //menuItem.command = (onclick) => this.abrirPagina(menu.url);
                menuItem.routerLink = ['/jsf', menu.url];
            }
            /*Se possuir subitens carrega-los atraves do metodo montarSubMenu*/
            if(menu.objetosMenu != null && menu.objetosMenu.length > 0){
                this.montarSubMenu(menuItem, menu.objetosMenu);
            }
            //this.model.push(menuItem);      
            itens.push(menuItem);       
        }
        //console.log(itens);
        let menuItemAplicativo: MenuItem = {};
        menuItemAplicativo.label = aplicativo.nomeAplicativo;
        menuItemAplicativo.icon = aplicativo.iconeAplicativo;
        menuItemAplicativo.items = itens;
        this.model.push(menuItemAplicativo);             
    }

    montarMenu(aplicativo: AplicativoMenu): void{
        //this.model = [];
        let itens: MenuItem[] = new Array();

        if(aplicativo.menu == null)
          return;

        for(let menu of aplicativo.menu){
            //INICIAR UM LOOP NO MENU PRINCIPAL, E POSTERIORMENTE VARRER TODA A ARVORE DESSE MENU PRINCIPAL, QUANDO TERMINAR A ÁRVORE DESSE MENU IR PARA O PRÓXIMO MENU PRINCIPAL
            //{label: 'Dashboard', icon: 'fa fa-fw fa-home', routerLink: ['/']},
            let menuItem: MenuItem = {};
            menuItem.label = menu.nomeObjeto;
            menuItem.icon = this.definirIcone(menu);
            if(ObjectUtilService.isNotBlank(menu.url)) menuItem.routerLink = [menu.url];
            /*SE A URL FOR DO TIPO .JSP OU .JSF VAI CARREGAR A PÁGINA DENTRO DA ROUTE /jsf QUE RECEBE COMO PARAMETRO A URL DESSE MENUITEM*/
            if(menu.url != '' && menu.url.indexOf('.js') != -1){
                //menuItem.command = (onclick) => this.abrirPagina(menu.url);
                menuItem.routerLink = ['/jsf', menu.url];
            }
            /*Se possuir subitens carrega-los atraves do metodo montarSubMenu*/
            if(menu.objetosMenu != null && menu.objetosMenu.length > 0){
                this.montarSubMenu(menuItem, menu.objetosMenu);
            }
            //this.model.push(menuItem);      
            itens.push(menuItem);       
        }
        //console.log(itens);
        let menuItemAplicativo: MenuItem = {};
        menuItemAplicativo.label = aplicativo.nomeAplicativo;
        menuItemAplicativo.icon = aplicativo.iconeAplicativo;
        menuItemAplicativo.items = itens;
        menuItemAplicativo.expanded = true;
        this.model.push(menuItemAplicativo);             
    }

    private montarSubMenu(parentMenu: any, subMenus: ObjetoMenu[]): void{
        for(let subMenu of subMenus){            
            let menuItem: MenuItem = {};
            menuItem.label = subMenu.nomeObjeto;
            menuItem.icon = this.definirIcone(subMenu);
            menuItem.expanded = true;
            if(ObjectUtilService.isNotBlank(subMenu.url)) menuItem.routerLink = [subMenu.url];
            if(subMenu.url != '' && subMenu.url.indexOf('.js') != -1){
                menuItem.routerLink = ['/jsf', subMenu.url];
            }
            if(subMenu.objetosMenu != null && subMenu.objetosMenu.length > 0){
                this.montarSubMenu(menuItem, subMenu.objetosMenu);
            }
            if(parentMenu.items == null) parentMenu.items = [];
            parentMenu.items.push(menuItem);
        }
    }
    
    private definirIcone(objetoMenu: ObjetoMenu){
        if(objetoMenu.icone == ''){
			if(objetoMenu.tipoObjetoMenu == 1)
				return objetoMenu.url == '' ? this.lbs.icon.menu : this.lbs.icon.subMenu;//'fa fa-folder-o' : 'fa fa-file-text-o';
			else if(objetoMenu.tipoObjetoMenu == 2)
				return objetoMenu.url == '' ? this.lbs.icon.menu : this.lbs.icon.subMenu;//'fa fa-folder-o' : 'fa fa-file-text-o';
			else if(objetoMenu.tipoObjetoMenu == 3)
				return this.lbs.icon.subMenuLink;//'fa fa-external-link';
			return this.lbs.icon.menu;//'fa fa-folder-o';
		}else{
			return objetoMenu.icone;
		}
    }
}

@Component({
  /* tslint:disable:component-selector */
    selector: '[app-submenu]',
  /* tslint:enable:component-selector */
    template: `
        <ng-template ngFor let-child let-i="index" [ngForOf]="(root ? item : item.items)">
            <li [ngClass]="{'active-menuitem': isActive(i)}" [class]="child.badgeStyleClass">
                <a [href]="child.url||'#'" (click)="itemClick($event,child,i)" *ngIf="!child.routerLink" (mouseenter)="onMouseEnter(i)"
				   [attr.tabindex]="!visible ? '-1' : null" [attr.target]="child.target">
					<span class="menuitem-text">{{child.label}}</span>
					<!-- <i class="pi pi-fw pi-angle-down layout-submenu-toggler" *ngIf="child.items"></i> -->
                    <i [ngClass]="child.icon"></i>
                </a>

                <a (click)="itemClick($event,child,i)" (mouseenter)="onMouseEnter(i)" *ngIf="child.routerLink"
                    [routerLink]="child.routerLink" routerLinkActive="active-menuitem-routerlink"
                   [routerLinkActiveOptions]="{exact: true}" [attr.tabindex]="!visible ? '-1' : null" [attr.target]="child.target">
					<span class="menuitem-text">{{child.label}}</span>
					<!-- <i class="pi pi-fw pi-angle-down layout-submenu-toggler" *ngIf="child.items"></i> -->
                    <i [ngClass]="child.icon"></i>
                </a>
                <ul app-submenu [item]="child" *ngIf="child.items && isActive(i)" [visible]="isActive(i)" [reset]="reset"
                    [parentActive]="isActive(i)" [@children]="(app.isHorizontal())&&root ? isActive(i) ?
                     'visible' : 'hidden' : isActive(i) ? 'visibleAnimated' : 'hiddenAnimated'"></ul>
            </li>
        </ng-template>
    `,
    animations: [
        trigger('children', [
            state('void', style({
                height: '0px'
            })),
            state('hiddenAnimated', style({
                height: '0px'
            })),
            state('visibleAnimated', style({
                height: '*'
            })),
            state('visible', style({
                height: '*',
                'z-index': 100
            })),
            state('hidden', style({
                height: '0px',
                'z-index': '*'
            })),
            transition('visibleAnimated => hiddenAnimated', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)')),
            transition('hiddenAnimated => visibleAnimated', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)')),
            transition('void => visibleAnimated, visibleAnimated => void',
                animate('400ms cubic-bezier(0.86, 0, 0.07, 1)'))
        ])
    ]
})
export class AppSubMenuComponent {

    @Input() item: MenuItem;

    @Input() root: boolean;

    @Input() visible: boolean;

    _reset: boolean;

    _parentActive: boolean;

    activeIndex: number;

    constructor(public app: AppMainComponent, public appMenu: AppMenuComponent) {}

    itemClick(event: Event, item: MenuItem, index: number) {
        if (this.root) {
            this.app.menuHoverActive = !this.app.menuHoverActive;
        }

        // avoid processing disabled items
        if (item.disabled) {
            event.preventDefault();
            return true;
        }

        // activate current item and deactivate active sibling if any
        this.activeIndex = (this.activeIndex === index) ? null : index;

        // execute command
        if (item.command) {
            item.command({originalEvent: event, item});
        }

        // prevent hash change
        if (item.items || (!item.url && !item.routerLink)) {
            event.preventDefault();
        }

        // hide menu
        if (!item.items) {
            if (this.app.isMobile()) {
                this.app.sidebarActive = false;
                this.app.menuMobileActive = false;
            }

            if (this.app.isHorizontal()) {
                this.app.resetMenu = true;
            } else {
                this.app.resetMenu = false;
            }

            this.app.menuHoverActive = !this.app.menuHoverActive;
        }
    }

    onMouseEnter(index: number) {
        if (this.root && this.app.menuHoverActive && this.app.isHorizontal()
            && !this.app.isMobile() && !this.app.isTablet()) {
            this.activeIndex = index;
        }
    }

    isActive(index: number): boolean {
        return this.activeIndex === index;
    }

    @Input() get reset(): boolean {
        return this._reset;
    }

    set reset(val: boolean) {
        this._reset = val;

        if (this._reset && (this.app.isHorizontal() || this.app.isOverlay())) {
            this.activeIndex = null;
        }
    }

    @Input() get parentActive(): boolean {
        return this._parentActive;
    }

    set parentActive(val: boolean) {
        this._parentActive = val;

        if (!this._parentActive) {
            this.activeIndex = null;
        }
    }
}
