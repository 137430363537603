import { RetornoResponse } from 'src/app/core/model/retorno-response';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ParamUtilService } from 'src/app/_util/param-util/param-util.service';
import { map, catchError, finalize } from 'rxjs/operators';

import { Lancamento } from '../model/lancamento';
import { ConfigCliente } from 'src/app/_config-cliente/config-cliente';
import { Observable, throwError } from 'rxjs';
import { DatePipe } from '@angular/common';

const MODULO = 'rh';
const CLASSE = '/lancamento/';
const PATH_CLASSE = ConfigCliente.APP_REST() + MODULO + CLASSE;
const INSERT = PATH_CLASSE + 'insert';
const UPDATE = PATH_CLASSE + 'update';
const DELETE = PATH_CLASSE + 'delete';
const LOAD = PATH_CLASSE + 'load-por-funcionario';
const LOADEDIT = PATH_CLASSE + 'loadEdit';
const LISTARLAZY = PATH_CLASSE + 'listarLazy';
const LISTARPORFUNCIONARIO = PATH_CLASSE + 'listarPorFuncionario';
const LISTARTOTAIS = PATH_CLASSE + 'listarTotais'; //mapa-terminais
const LISTARTOTALPORLOCALIZACAO = PATH_CLASSE + 'listarTotalPorLocalizacao'; //dashboard

@Injectable({
  providedIn: 'root',
})
export class LancamentoService {
  constructor(private httpClient: HttpClient, private paramUtilService: ParamUtilService) {}

  /*EDIT*/
  salvar(bean: Lancamento) {
    let path = bean.id == null ? INSERT : UPDATE;

    var datePipe = new DatePipe('pt-BR');
    bean.entradaString = datePipe.transform(bean.entrada, 'dd/MM/yyyy HH:mm');
    bean.saidaString = datePipe.transform(bean.saida, 'dd/MM/yyyy HH:mm');
    bean.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    return this.httpClient.post<RetornoResponse>(path, bean, {}).pipe(
      map((res) => {
        if (res.codigo == 1 || res.retorno == null) throw res.mensagem;
        return res;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  excluir(id: number) {
    return this.httpClient.post<RetornoResponse>(DELETE, id, {}).pipe(
      map((res) => {
        if (res.codigo == 1) throw res.mensagem;
        return res;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  /*LIST*/

  // listarTodos() {
  //   return this.httpClient.post<RetornoResponse>(null, null, {})
  //     .pipe(map(res => {
  //       if(res.codigo == 1) throw res.mensagem;
  //       return res.retorno;
  //     })
  //     ,catchError(error => {
  //       return throwError(error);
  //     })
  // }

  listarLazy() {
    return this.httpClient.post<RetornoResponse>(LISTARLAZY, null, {}).pipe(
      map((res) => {
        if (res.codigo == 1) throw res.mensagem;
        return res.retorno;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  ListarPorFuncionario() {
    return this.httpClient.get<RetornoResponse>(LISTARPORFUNCIONARIO, {}).pipe(
      map((res) => {
        if (res.codigo == 1) throw res.mensagem;
        return res.retorno;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  /*LOAD*/

  // load(id: any) {
  //   const p = this.paramUtilService.jsonToParams({id: id});
  //   return this.httpClient.get<RetornoResponse>(LOAD, {params: p}).pipe(
  //     map(res => {
  //       if(res.codigo == 1) throw res.mensagem;
  //       return res.retorno;
  //     }),
  //     catchError(error => {
  //       return throwError(error);
  //     })
  //   );
  // }

  // loadEdit(id: any) {
  //   const p = this.paramUtilService.jsonToParams({ id: id });
  //   return this.httpClient.get<RetornoResponse>(LOADEDIT, { params: p }).pipe(
  //     map(res => {
  //       if (res.codigo == 1) throw res.mensagem;
  //       return res.retorno;
  //     }),
  //     catchError(error => {
  //       return throwError(error);
  //     })
  //   );
  // }

  load(id: number) {
    const p = this.paramUtilService.jsonToParams({ id: id });
    return this.httpClient.get<RetornoResponse>(LOAD, { params: p }).pipe(
      map((res) => {
        if (res.codigo == 1) throw res.mensagem;
        return res.retorno;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  /*OUTROS*/
}
