import { RetornoResponse } from 'src/app/core/model/retorno-response';
import { HttpClient, HttpParams } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class AbstractGenericHttpService {
  constructor(protected httpClient: HttpClient) {}

  protected _doReturn(req: Observable<RetornoResponse>): Observable<any> {
    return this._returnFull(req);
  }

  /** */
  protected _doReturnRetorno(req: Observable<RetornoResponse>): Observable<any> {
    return this._returnRetorno(req);
  }

  /** */

  protected _doDelete(url: string, p?: HttpParams): Observable<any> {
    return this._returnFull(this.httpClient.delete<RetornoResponse>(url, { params: p }));
  }

  protected _doGet(url: string, p?: HttpParams): Observable<any> {
    return this._returnFull(this.httpClient.get<RetornoResponse>(url, { params: p }));
  }

  protected _doGetRetorno(url: string, p?: HttpParams): Observable<any> {
    return this._returnRetorno(this.httpClient.get<RetornoResponse>(url, { params: p }));
  }

  protected _doPut(url: string, data?: any, p?: HttpParams): Observable<any> {
    return this._returnFull(this.httpClient.put<RetornoResponse>(url, data, { params: p }));
  }

  protected _doPutRetorno(url: string, data: any, p?: HttpParams): Observable<any> {
    return this._returnRetorno(this.httpClient.put<RetornoResponse>(url, data, { params: p }));
  }

  protected _doPost(url: string, data: FormData | any): Observable<any> {
    return this._returnFull(this.httpClient.post<RetornoResponse>(url, data));
  }

  protected _doPostRetorno(url: string, data: any): Observable<any> {
    return this._returnRetorno(this.httpClient.post<RetornoResponse>(url, data));
  }

  protected _doPostBlob(url: string): Observable<any> {
    return this.httpClient.post(url, null, { observe: 'response', responseType: 'blob' }).pipe(
      map((res) => {
        console.log(res.headers);
        console.log(res.headers.get('Content-Type'));
        console.log(res.headers.get('Content-Disposition'));
        console.log(res.headers.get('filename'));
        return res;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  private _returnRetorno(req: Observable<RetornoResponse>): Observable<any> {
    return req.pipe(
      map((res) => {
        if (res.codigo == 1) throw res.mensagem;
        return res.retorno;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  private _returnFull(req: Observable<RetornoResponse>): Observable<any> {
    return req.pipe(
      map((res) => {
        if (res.codigo == 1) throw res.mensagem;
        return res;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  // protected _url(...args): string {
  //   let url = args[0];
  //   return;
  // }
}
