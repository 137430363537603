import { RetornoResponse } from 'src/app/core/model/retorno-response';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ConfigCliente } from 'src/app/_config-cliente/config-cliente';
import { ParamUtilService } from 'src/app/_util/param-util/param-util.service';

const PATH = ConfigCliente.APP_REST() + 'rh/folhaponto/';
const GERARPDF = PATH + 'gerarPdfFuncionario';

@Injectable({
  providedIn: 'root',
})
export class FolhaPontoService {
  constructor(private httpClient: HttpClient, private paramUtil: ParamUtilService) {}

  gerarPdfFuncionario() {
    const p = this.paramUtil.jsonToParams({ periodo: '05/2021' });
    return this.httpClient.get<RetornoResponse>(GERARPDF, { params: p }).pipe(
      map((res) => {
        if (res.codigo == 1) throw res.mensagem;
        return res.retorno;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }
}
