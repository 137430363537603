import { Injectable } from '@angular/core';
import { Router, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { RhAlterarSenhaService } from 'src/app/m_rh/services/rh-alterar-senha.service';

@Injectable({
  providedIn: 'root',
})
export class RhAlterarSenhaResolverService {
  constructor(private rhAlterarSenhaService: RhAlterarSenhaService, private router: Router) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any | Observable<any> | Promise<any> {
    return this.rhAlterarSenhaService.alterarSenha1();
  }
}
