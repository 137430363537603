import { Observable, forkJoin } from 'rxjs';
import { Router, Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { InventarioService } from 'src/app/m_estoque/services/inventario.service';
import { MaterialService } from 'src/app/m_estoque/services/material.service';
import { EstadoConservacaoService } from 'src/app/m_estoque/services/estado-conservacao.service';
import { Inventario } from 'src/app/m_estoque/model/inventario';

@Injectable({
  providedIn: 'root',
})
export class InventarioLoadService implements Resolve<any> {
  public static ESTADO_CONSERVACAO: number = 0;
  public static SITUACOES: number = 1;
  public static INVENTARIOS: number = 2;
  public static INVENTARIO: number = 2;
  public static ITENS_INVENTARIO: number = 3;

  constructor(
    private inventarioService: InventarioService,
    private estadoConservacao: EstadoConservacaoService,
    private router: Router
  ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any | Observable<any> | Promise<any> {
    //throw new Error("Method not implemented.");
    let loadPile: Observable<any>[] = [];
    loadPile.push(this.estadoConservacao.listar());
    loadPile.push(this.inventarioService.situacoes());
    if (state.url.includes('/edit')) {
      let id: number = Number.parseInt(route.paramMap.get('id'));
      loadPile.push(this.inventarioService.get(id));
      loadPile.push(this.inventarioService.itens(new Inventario({ id: id })));
    }
    if (state.url.includes('/list')) {
      loadPile.push(this.inventarioService.inventarios());
    }
    return forkJoin(loadPile);
  }
}
