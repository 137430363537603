import { ConfigCliente } from 'src/app/_config-cliente/config-cliente';

export class Path {
  // static readonly APP_ARQ = ConfigCliente.APP_ARQ;

  static readonly TESTE = ConfigCliente.APP_REST() + 'acesso/usuario/teste';

  static readonly LOGO = ConfigCliente.APP_MAIN() + 'config-cliente/logo/logo.jpg';

  //MODULOS
  static readonly TBL = ConfigCliente.APP_REST() + 'pwa/tabela/';
  static readonly IND = ConfigCliente.APP_REST() + 'pwa/indice/';
  static readonly RH = ConfigCliente.APP_REST() + 'pwa/rh/';

  /**BEACON */
  /**gateway */
  static readonly IOT_GATEWAY = 'beacon/gateway/';

  /**device */
  static readonly IOT_DEVICE = 'beacon/device/';
  static readonly IOT_DEVICE_INSERT = ConfigCliente.APP_REST() + 'beacon/device/insert';
  static readonly IOT_DEVICE_UPDATE = ConfigCliente.APP_REST() + 'beacon/device/update';
  static readonly IOT_DEVICE_DELETE = ConfigCliente.APP_REST() + 'beacon/device/delete';
  static readonly IOT_DEVICE_LOAD = ConfigCliente.APP_REST() + 'beacon/device/load';
  static readonly IOT_DEVICE_LISTARLAZY = ConfigCliente.APP_REST() + 'beacon/device/listarLazy';
  static readonly IOT_DEVICE_LISTARTOTALPORLOCALIZACAO =
    ConfigCliente.APP_REST() + 'beacon/device/listarTotalPorLocalizacao'; //dashboard

  // /**ACESSO */
  static readonly ACS_USUARIO_RH_ALTERAR_SENHA = ConfigCliente.APP_REST() + 'pwa/acesso/usuario/alterarSenha';
  static readonly ACS_USUARIO_LOGIN = ConfigCliente.APP_REST() + 'pwa/acesso/usuario/login';
  static readonly ACS_USUARIO_RECUPERARSENHA = ConfigCliente.APP_REST() + 'pwa/acesso/usuario/recuperar-senha';
  static readonly ACS_USUARIO_ALTERARSENHA = ConfigCliente.APP_REST() + 'pwa/acesso/usuario/alterar-senha';
  static readonly ACS_USUARIO_USUARIOEMAIL = ConfigCliente.APP_REST() + 'pwa/acesso/usuario/usuario-email';
  
  // static readonly ACS_USUARIO_RH_ALTERAR_SENHA = ConfigCliente.APP_REST + 'pwa/acesso/usuario/alterarSenha';
  // static readonly ACS_USUARIO_LOGIN = ConfigCliente.APP_REST + 'pwa/acesso/usuario/login';
  // static readonly ACS_USUARIO_RECUPERARSENHA = ConfigCliente.APP_REST + 'pwa/acesso/usuario/recuperar-senha';
  // static readonly ACS_USUARIO_ALTERARSENHA = ConfigCliente.APP_REST + 'pwa/acesso/usuario/alterar-senha';
  // static readonly ACS_USUARIO_USUARIOEMAIL = ConfigCliente.APP_REST + 'pwa/acesso/usuario/usuario-email';
  // /**Vai listar toda a TREE do menu que o usuário logado tem acesso, sendo que o início da TREE é o aplicativo, os demais itens são os objetosMenu */
  static readonly ACS_USUARIO_CARREGARMENU = ConfigCliente.APP_REST() + 'acesso/usuario/carregarMenu';
  static readonly ACS_USUARIO_LISTARUNIDADE = ConfigCliente.APP_REST() + 'acesso/usuario/listarUnidade';
  static readonly ACS_USUARIO_ATUALIZARSESSAO = ConfigCliente.APP_REST() + 'acesso/usuario/atualizarSessao';
  static readonly ACS_USUARIO_LOGINJSF = ConfigCliente.APP_MAIN() + 'servico/autenticacao/login.jsf?';
}
