import { AuthResolverService } from './auth/auth-resolver.service';
import { AuthGuard } from './auth/guard/auth.guard';
import { Routes, RouterModule } from '@angular/router';
import { ModuleWithProviders } from '@angular/core';
import { AppMainComponent } from './app.main.component';
import { AppNotfoundComponent } from './pages/app.notfound.component';
import { AppErrorComponent } from './pages/app.error.component';
import { AppAccessdeniedComponent } from './pages/app.accessdenied.component';
import { AppLoginComponent } from './auth/login/app.login.component';
import { FrameJsfComponent } from './_util/frame-jsf/frame-jsf.component';
import { LancamentoComponent } from './m_rh/pages/lancamento/lancamento.component';
import { InventarioListComponent } from './m_estoque/pages/inventario/inventario-list/inventario-list.component';
import { InventarioEditComponent } from './m_estoque/pages/inventario/inventario-edit/inventario-edit.component';
import { InventarioLoadService } from './m_estoque/pages/inventario/resolver/inventario-load.service';
import { RecuperarSenhaComponent } from './auth/recuperar-senha/recuperar-senha.component';
import { AlterarSenhaComponent } from './auth/alterar-senha/alterar-senha.component';

export const routes: Routes = [
    /*my routes*/
    

    /*primeng*/
    { path: '', component: AppMainComponent, resolve: { authResolver: AuthResolverService },
        children: [
          //{ path: '', component: LancamentoComponent, canActivate: [AuthGuard], canLoad: [AuthGuard], resolve: { authResolver: AuthResolverService }  },
            { path: '', component: LancamentoComponent, canActivate: [AuthGuard], canLoad: [AuthGuard] },
            //{ path: '', component: DashboardDemoComponent, canActivate: [AuthGuard], canLoad: [AuthGuard] },
            { path: 'jsf/:url', component: FrameJsfComponent, canActivate: [AuthGuard], canLoad: [AuthGuard] },
            { path: 'estoque', children: [   
                { path: 'inventario', children: [
                  {path: 'list', component: InventarioListComponent, canActivate: [AuthGuard], canLoad: [AuthGuard],resolve:{loadResolver:InventarioLoadService}},
                  {path: 'new', component: InventarioEditComponent, canActivate: [AuthGuard], canLoad: [AuthGuard],resolve:{loadResolver:InventarioLoadService}},
                  {path: 'edit/:id', component: InventarioEditComponent, canActivate: [AuthGuard], canLoad: [AuthGuard],resolve:{loadResolver:InventarioLoadService}},
                ]}
              ]
            }
            //  { path: 'forms', component: FormsDemoComponent },
            //  { path: 'data', component: DataDemoComponent },
            //  { path: 'panels', component: PanelsDemoComponent },
            //  { path: 'overlays', component: OverlaysDemoComponent },
            // { path: 'menus', component: MenusDemoComponent },
            // { path: 'messages', component: MessagesDemoComponent },
            // { path: 'misc', component: MiscDemoComponent },
            // { path: 'empty', component: EmptyDemoComponent },
            // { path: 'charts', component: ChartsDemoComponent },
            // { path: 'file', component: FileDemoComponent },
            // { path: 'documentation', component: DocumentationComponent }
        ]
    },
    {path: 'error', component: AppErrorComponent},
    {path: 'accessdenied', component: AppAccessdeniedComponent},
    {path: 'notfound', component: AppNotfoundComponent},    
    {path: 'login', component: AppLoginComponent},
    {path: 'recuperar-senha', component: RecuperarSenhaComponent},
    {path: 'recuperar-senha/:token', component: AlterarSenhaComponent},
    {path: '**', redirectTo: '/notfound'},

];

export const AppRoutes: ModuleWithProviders = RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'});
