import { Injectable } from '@angular/core';

@Injectable()
export class ObjectUtil {
  constructor() {}

  /**Verificar se o valor é NULL, UNDEFINED, 'null', '' (ou vazio)*/
  static isBlank(valor: any): boolean {
    return valor == null
      ? true
      : valor == undefined
      ? true
      : valor == 'null'
      ? true
      : valor.toString().trim() == ''
      ? true
      : false;
  }

  /**Verificar se o valor é diferente de NULL, UNDEFINED, 'null', '' (ou vazio)*/
  static isNotBlank(valor: any): boolean {
    return !this.isBlank(valor);
  }

  /**Verifica se o objeto não é NULL, ou UNDEFINED */
  static isNotNull(objeto: any): boolean {
    return objeto == null ? false : objeto == undefined ? false : true;
  }

  /**Verifica se o objeto é NULL, ou UNDEFINED */
  static isNull(objeto: any): boolean {
    return !this.isNotNull(objeto);
  }

  /**Verifica se todos registros da lista são nulos*/
  static isAllNull(lista: Array<any>): boolean {
    for (let l of lista) {
      if (this.isNotNull(l)) return false;
    }
    return true;
  }

  /**Verifica se todos registros da lista são nulos*/
  static isAllNotNull(lista: Array<any>): boolean {
    for (let l of lista) {
      if (this.isNull(l)) return false;
    }
    return true;
  }

  /**Verifica se o ID do objeto é NULL*/
  static isIdNull(objeto: any): boolean {
    return objeto == null ? true : objeto.id == null ? true : false;
  }
  static isIdNotNull(objeto: any): boolean {
    return !this.isIdNull(objeto);
  }

  /**Verifica se o ID do objeto é != null, 0 ou undefined*/
  static isIdValid(id: number): boolean {
    return id == null || id == undefined || id == 0 ? false : true;
  }

  /**Verifica se o ID do objeto é != null, 0 ou undefined*/
  static isIdInvalid(id: number): boolean {
    return !this.isIdValid(id);
  }

  /**Verifica se o campo VALOR é igual a algum item presenta na LISTA, se sim retorna TRUE indicando que a lista contem o VALOR, se não retorna FALSE */
  static contains(valor: any, lista: Array<any>): boolean {
    for (let l of lista) {
      if (valor == l) return true;
    }
    return false;
  }

  /**Verifica se o campo VALOR é igual a algum item presenta na LISTA, se sim retorna TRUE indicando que a lista contem o VALOR, se não retorna FALSE */
  static notContains(valor: any, lista: Array<any>): boolean {
    return !this.contains(valor, lista);
  }

  /**Verifica se lista é NULL, ou UNDEFINED, ou está vazia */
  static isEmpty(objeto: any[]): boolean {
    return objeto == null
      ? true
      : objeto == undefined
      ? true
      : objeto.length == NaN
      ? true
      : objeto.length == 0
      ? true
      : false;
  }

  /**Verifica se lista possui registros, deve ser diferente de null, undefined, e vazia */
  static isNotEmpty(objeto: any[]): boolean {
    return !this.isEmpty(objeto);
  }

  /**Verifica se o valor é diferente de null, undefined e igual a TRUE */
  static isTrue(valor: boolean): boolean {
    return this.isNotNull(valor) && valor == true;
  }

  /**Verifica se o valor é diferente de null, undefined e igual a FALSE */
  static isFalse(valor: boolean): boolean {
    return this.isNotNull(valor) && valor == true;
  }

  static isString(valor: any): boolean {
    return valor instanceof String || typeof valor == 'string';
  }

  /**Se valor for NULL ou UNDEFINED, retorna o valor DEFAULT, se não, retorna o próprio valor */
  static default(valor: any, valorDefault: any): any {
    return this.isNotNull(valor) ? valor : valorDefault;
  }

  /**Retorna o valor do ID desse objeto, se objeto for NULO retorno NULL */
  static getId(objeto: any): number {
    return objeto == null ? null : objeto.id;
  }

  static toBoolean(valor: any): boolean {
    let retorno;
    switch (valor) {
      case 'false':
        retorno = false;
        break;
      case 'true':
        retorno = true;
        break;
      case '0':
        retorno = false;
        break;
      case '1':
        retorno = true;
        break;
      default:
        retorno = null;
        break;
    }
    return retorno;
  }

  static containsLetters(value: any): boolean {
    //var regexLetters = /[a-zA-Z]/g; // MAIÚSCULO E MINÚSCULO EXPLÍCITO
    var regexLetters = /[a-z]/i; // I - CASE INSENSITIVE
    if (regexLetters.test(value)) {
      return true;
    }
    return false;
  }
}
