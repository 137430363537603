import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PreCadastroModule } from 'src/app/m_rh/pages/pre-cadastro/pre-cadastro.module';
import { LancamentoModule } from './pages/lancamento/lancamento.module';
import { PaginasRhRoutes } from './paginas-rh.routes';
import { FormsModule } from '@angular/forms';
import { RhAlterarSenhaModule } from './pages/rh-alterar-senha/rh-alterar-senha.module';
import { AlterarEmailModule } from './pages/alterar-email/alterar-email.module';
import { SelectButtonModule } from 'primeng/primeng';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    FormsModule,
    /*MY ROUTINGS*/
    PaginasRhRoutes,
    /*MY MODULES*/
    RhAlterarSenhaModule,
    LancamentoModule,
    PreCadastroModule,
    AlterarEmailModule,
    SelectButtonModule,
  ],
})
export class PaginasRhModule {}
