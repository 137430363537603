import { ViewMode } from 'src/app/core/constant/view-mode.enum';
import { Path } from 'src/app/core/constant/path';
import { RetornoResponse } from 'src/app/core/model/retorno-response';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

//import 'rxjs/Rx';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { ParamUtilService } from './../_util/param-util/param-util.service';
import { UsuarioLogin } from './domain/usuario-login';
import { UsuarioSessao } from './domain/usuario-sessao';

@Injectable()
export class AuthService {
  /**EXEMPLO UTILIZADO http://angularjs.blogspot.com.br/2016/11/easy-angular-authentication-with-json.html */

  jwtHelper: JwtHelperService = new JwtHelperService();

  constructor(
    private router: Router,
    private httpClient: HttpClient,
    private paramUtilService: ParamUtilService
  ) {}

  /*Recuperar Senha*/
  recuperarSenha(cpf: string) {
    return this.httpClient.post<RetornoResponse>(Path.ACS_USUARIO_RECUPERARSENHA, { cpf }).pipe(
      map((res) => {
        return res;
      }),
      catchError((error: HttpErrorResponse) => {
        return throwError(error);
      })
    );
  }

  /*Alterar Senha*/
  alterarSenha(novaSenha: string, token: string) {
    return this.httpClient.post<RetornoResponse>(Path.ACS_USUARIO_ALTERARSENHA, { novaSenha, token }).pipe(
      map((res) => {
        return res;
      }),
      catchError((error: HttpErrorResponse) => {
        return throwError(error);
      })
    );
  }

  /*LOGIN*/
  login(usuarioLogin: UsuarioLogin) {
    return this.httpClient.post<RetornoResponse>(Path.ACS_USUARIO_LOGIN, usuarioLogin, {}).pipe(
      map((res) => {
        let usuarioSessao: UsuarioSessao = res.retorno;
        localStorage.setItem('nomeUsuario', usuarioSessao.nomeUsuario);
        localStorage.setItem('imagemUsuario', usuarioSessao.imagemUsuario);
        localStorage.setItem('token', res.retorno.token); //guarda o token no localStorage
        localStorage.setItem('tokenExpirou', 'false');
        localStorage.setItem('idUnidadeAtiva', usuarioSessao.idUnidadeAtiva.toString());
        //this.logJwt();
        this.router.navigate(['/']); //redireciona usuário para home
        return res;
      }),
      catchError((error: HttpErrorResponse) => {
        return throwError(error);
      })
    );
  }

  loginJSF(usuarioLogin: UsuarioLogin) {
    //TEMP//código temporário que vai existir enquanto for necessário fazer o login no JSF
    const p = this.paramUtilService.jsonToParams(usuarioLogin);
    return this.httpClient
      .get(Path.ACS_USUARIO_LOGINJSF, { params: p }) // + `login=${usuarioLogin.$login}&senha=${usuarioLogin.$senha}`)
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((error) => {
          return Observable.throw('');
        })
      );
    //TEMP
  }

  atualizarSessao() {
    return this.httpClient
      .get<RetornoResponse>(Path.ACS_USUARIO_ATUALIZARSESSAO) //com o httpClient por padrão é retornado um json, e se quiser definir seu tipo é colocado entre <> após o get ou feito um cast dentro do retorno.
      .pipe(
        map((res) => {
          return res.retorno;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  /***SEM AJAX*/

  /*LOGOUT*/
  logout() {
    console.log('-> Redirecionando página para LOGIN');
    localStorage.clear();
    sessionStorage.clear();
    this.router.navigate(['/login']);
  }

  /**VERIFICA SE ESTÁ LOGADO*/
  loggedIn() {
    //this.logJwt();
    try {
      let loggedIn: Boolean = !this.isTokenExpired();
      if (!loggedIn) {
        //se token expirou vai verificar se existe a variável tokenExpirou
        if (localStorage.getItem('tokenExpirou') == 'false') {
          //se a variável existe e é FALSE setta como TRUE e faz o LOGOUT
          console.log('-> Sessão usuário expirou');
          localStorage.setItem('tokenExpirou', 'true');
          this.logout();
        } else {
          //se a variável for NULL ou se for TRUE e a rota for diferente de '/login' vai fazer o LOGOUT
          if (localStorage.getItem('tokenExpirou') == null || localStorage.getItem('tokenExpirou') == 'true') {
            if (!this.router.url.includes('/login') && !this.router.url.includes('/recuperar-senha')) {
              this.logout();
            }
          }
        }
      }
      return loggedIn;
    } catch (error) {
      return false;
    }
  }

  logJwt() {
    var token = localStorage.getItem('token');
    console.log(token);
    console.log('isTokenExpired -> ' + this.isTokenExpired());
    if (token != null) {
      console.log('token -> ' + token);
      console.log('isTokenExpired -> ' + this.isTokenExpired());
      console.log('getTokenExpirationDate -> ' + this.jwtHelper.getTokenExpirationDate(token));
      console.log(this.jwtHelper.decodeToken(token));
      console.log('-> getTokenExpirationDate: ' + this.jwtHelper.getTokenExpirationDate(token));
      console.log(this.isTokenExpired());
    }
  }

  isTokenExpired() {
    var token = localStorage.getItem('token');
    return this.jwtHelper.isTokenExpired(token);
  }

  static isViewDf(): boolean {
    const view = sessionStorage.getItem('view');
    return view === ViewMode.DEFAULT;
  }
}
