export class UsuarioLogin{

    private login: string;
    private senha: string;

    constructor(){}


	public get $login(): string {
		return this.login;
	}

	public set $login(value: string) {
		this.login = value;
	}

	public get $senha(): string {
		return this.senha;
	}

	public set $senha(value: string) {
		this.senha = value;
	}
    

}