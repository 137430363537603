import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';
import { ConfigCliente } from 'src/app/_config-cliente/config-cliente';
import { GenericEntity } from 'src/app/core/model/generic-entity';
import { ParamUtilService } from 'src/app/_util/param-util/param-util.service';
import { Inventario } from '../model/inventario';
import { ItemInventario } from '../model/item-inventario';

const MODULO = 'estoque';
const CLASSE = '/inventario';
const PATH_CLASSE = ConfigCliente.APP_REST() + MODULO + CLASSE;
const ITENS = PATH_CLASSE + '/{id}/itens';
const LISTA_INVENTARIOS = PATH_CLASSE + '/usuario-sessao/listar';
const INVENTARIO_ATUAL = PATH_CLASSE + '/atual';
const SITUACOES = PATH_CLASSE + '/situacoes';
const SALVAR_ITENS = PATH_CLASSE + '/{id}/itens';
const SALVAR_ITEM = PATH_CLASSE + '/{id}/item';
const EXCLUIR_ITENS = PATH_CLASSE + '/{id}/delete-itens';
const EXCLUIR_ITEM = PATH_CLASSE + '/{id}/delete-item';
const URL_IMAGEM_ITEM = PATH_CLASSE + '/item/image/{anexo}';

@Injectable({
  providedIn: 'root',
})
export class InventarioService {
  constructor(
    private httpClient: HttpClient,
    private paramUtilService: ParamUtilService,
    private AuthService: AuthService
  ) {}
  getUrlImagemItem(): string {
    return URL_IMAGEM_ITEM;
  }
  urlImagemItem(item: ItemInventario): string | ArrayBuffer {
    if (item.anexo == undefined) return undefined;
    let url: string | ArrayBuffer = URL_IMAGEM_ITEM.replace('{anexo}', item.anexo);
    return url;
  }
  imagemItem(img: string): Observable<any> {
    return this.httpClient.get(img, {
      headers: {
        authorization: 'Bearer ' + localStorage.getItem('token'),
      },
      responseType: 'blob',
    });
  }
  salvar(bean: Inventario): Observable<any> {
    if (bean.id == undefined) {
      return this.httpClient.post<any>(PATH_CLASSE + '/', bean);
    } else {
      return this.httpClient.put<any>(PATH_CLASSE + '/', bean);
    }
  }
  excluir(id: number): Observable<any> {
    return this.httpClient.delete<any>(PATH_CLASSE + '/' + id);
  }
  get(id: number): Observable<any> {
    return this.httpClient.get<any>(PATH_CLASSE + '/' + id);
  }
  itens(bean: Inventario): Observable<ItemInventario[]> {
    return this.httpClient.get<ItemInventario[]>(ITENS.replace('{id}', bean.id + ''));
  }
  inventarios(): Observable<Inventario[]> {
    return this.httpClient.get<Inventario[]>(LISTA_INVENTARIOS);
  }
  atual(): Observable<Inventario> {
    return this.httpClient.get<Inventario>(INVENTARIO_ATUAL);
  }
  situacoes(): Observable<GenericEntity[]> {
    return this.httpClient.get<GenericEntity[]>(SITUACOES);
  }
  salvarItens(bean: Inventario, itens: ItemInventario[]): Observable<any> {
    return this.httpClient.post<any>(SALVAR_ITENS.replace('{id}', bean.id + ''), itens);
  }
  salvarItem(bean: Inventario, item: ItemInventario): Observable<any> {
    return this.httpClient.post<any>(SALVAR_ITEM.replace('{id}', bean.id + ''), item);
  }
  excluirItens(bean: Inventario, itens: ItemInventario[]): Observable<any> {
    return this.httpClient.post<any>(EXCLUIR_ITENS.replace('{id}', bean.id + ''), itens);
  }
  excluirItem(bean: Inventario, item: ItemInventario): Observable<any> {
    return this.httpClient.post<any>(EXCLUIR_ITEM.replace('{id}', bean.id + ''), item);
  }
}
