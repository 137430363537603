import { HttpParams } from '@angular/common/http';
//import {URLSearchParams, RequestOptions} from '@angular/commom';
import { Injectable } from '@angular/core';

@Injectable()
export class ParamUtilService {
  constructor() {}

  // jsonToOptions(parametros: any): RequestOptions{
  //   let params: URLSearchParams = new URLSearchParams();
  //   for (let key in parametros) {
  //     params.append(key, parametros[key]);
  //   }
  //   let options: RequestOptions = new RequestOptions();
  //   options.params = params;
  //   return options;
  // }

  jsonToParams(parametros: any): HttpParams {
    //como: HttpParams is intended to be immutable. The set and append methods don't modify the existing instance. Instead they return new instances
    //para funcionar o for é necessário atribuir novamente o valor do param a cada for (semelhante ao BigDecimal no java).
    let params: HttpParams = new HttpParams();
    for (let key in parametros) {
      params = params.set(key, parametros[key]);
    }
    return params;
  }

  /**Converte um json e objeto formData*/
  jsonToFormData(parametros: any): FormData {
    let formData = new FormData();
    for (let key in parametros) {
      formData.append(key, parametros[key]);
    }
    //console.log('jsontoformdata-----');

    //console.log(formData);
    return formData;
  }

  /**vai incorporar na url os valores das propriedades do json que possuírem o  mesmo nome do parâmetro da URL,
   * por exemplo: json = {id: 1} e url = 'url/{id}/' vai retornar 'url/1/'
   */
  jsonToUrl(url: string, parametros: any): string {
    for (let key in parametros) {
      if (url.indexOf(key)) {
        url = url.replace(`{${key}}`, parametros[key]);
      }
    }
    return url;
  }

  toJsonBlob(bean: any): any {
    return new Blob([JSON.stringify(bean)], { type: 'application/json' });
  }
}
