import { ConfigCliente } from 'src/app/_config-cliente/config-cliente';
import { EstadoConservacao } from './estado-conservacao';
import { Material } from './material';

export class ItemInventario {
  id: number;
  material: Material;
  estadoConservacao: EstadoConservacao;
  codigoPatrimonio: string;
  observacaoFuncionario: string;
  anexo: string;
  uploadAnexo: string | ArrayBuffer;
  idUsuarioCriacao: number;
  dataCriacao: Date;
  idUsuarioAtualizacao: number;
  dataAtualizacao: Date;
  _id: string;
  _anexoNome: string;
  _urlAnexo: string;
  constructor(bean?: any) {
    if (bean != null) {
      for (let key of Object.keys(bean)) {
        if (bean[key] != null) this[key] = bean[key];
      }
    }
    let MODULO = 'estoque';
    let CLASSE = '/inventario';
    let PATH_CLASSE = ConfigCliente.APP_REST() + MODULO + CLASSE;
    let URL_IMAGEM_ITEM = PATH_CLASSE + '/item/image/{anexo}';
    this._urlAnexo = URL_IMAGEM_ITEM.replace('{anexo}', this.anexo);
  }
}
