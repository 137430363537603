import { PropriedadesRh } from './../../../../core/modulos/rh/constant/propriedades-tabela';
import { ExameCandidatoEdit } from './../model/exame-candidato-edit';
import { RetornoResponse } from 'src/app/core/model/retorno-response';
import { CandidatoVagaHttpService } from 'src/app/core/modulos/rh/http/candidato-vaga-http.service';
import { FormUtil } from 'src/app/util/service/form-util.service';
import { LoadingService } from 'src/app/_util/loading/loading.service';
import { InputUtilService } from 'src/app/_util/input-util/input-util.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { MessageUtilService } from 'src/app/util/service/message-util.service';
import { Labels } from 'src/app/core/constant/labels';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-exames',
  templateUrl: './exames.component.html',
  styleUrls: ['./exames.component.scss'],
})
export class ExamesComponent implements OnInit {
  cfg: any = {};
  subscription: Subscription = new Subscription();
  listaExamesCandidato: ExameCandidatoEdit[];
  itemSelecionado: ExameCandidatoEdit;
  form: FormGroup;
  maxSizeAnexo = 5120000; //5 mb em bytes
  cpf: string;

  ID_REJEITADO = PropriedadesRh.SITUACAOEXAME_REJEITADO;

  constructor(
    public lbs: Labels,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private inputUtil: InputUtilService,
    public msgUtil: MessageUtilService,
    private loadingService: LoadingService,
    private candidatoVagaHttpService: CandidatoVagaHttpService
  ) {}

  ngOnInit() {
    this.carregarPagina();
  }

  carregarPagina(): void {
    /*LÓGICA DE CARREGAMENTO DA PÁGINA*/
    this.subscription.add(
      this.activatedRoute.data.subscribe(
        (data: { dataResolver: any }) => {
          this.listaExamesCandidato = data.dataResolver[0];
          this.cpf = data.dataResolver[1];
          this.loadingService.off();
          this.carregarForm();
        },
        (error) => {
          this.loadingService.off();
        }
      )
    );
  }

  //EDIT

  selecionar(item: ExameCandidatoEdit): void {
    this.itemSelecionado = item;
    this.carregarForm();
  }

  salvarItemSelecionado(): void {
    if (this.validarForm()) {
      this.loadingService.on();
      let bean = this.form.value;
      this.itemSelecionado.exame = bean;
      this.candidatoVagaHttpService.salvarExameCandidato(this.itemSelecionado, this.idVaga).subscribe(
        (next: RetornoResponse) => {
          this.loadingService.off();
          this.msgUtil.sucessPop(next.mensagem);
          this.itemSelecionado = null;
          this.listarItens();
        },
        (error) => {
          this.msgUtil.error(error);
          //this._warning(error, true, false);
          this.loadingService.off();
        },
        () => {
          //this.loadingService.off();
        }
      );
    }
  }

  listarItens(): void {
    this.loadingService.on();
    this.candidatoVagaHttpService.listarExamesVaga(this.idVaga).subscribe((next) => {
      this.loadingService.off();
      this.listaExamesCandidato = next;
    });
  }

  //FORM

  //prettier-ignore
  carregarForm(): void {
    this.carregarConfig();//CARREGA AS CONFIGURAÇÕES DOS CAMPOS ANTES DE CRIAR O FORM, POIS ESTE PODE DEPENDER DOS VALORES DE CONFIGURAÇÃO
    this.form = this.formBuilder.group({ //PARA A INTEGRAÇÃO ENTRE O BEAN E OS CONTROLS FUNCIONAREM O FORMCONTROLNAME DEVEM POSSUIR O MESMO NOME DO CAMPO DO BEAN AO QUAL ELE CORRESPONDE.
    });
    //this.form.setValue(this.json_teste);
  }

  validarForm(): boolean {
    //pristine é o contrário do dirty
    if (this.form.invalid) {
      FormUtil.markAllAsDirty(this.form);
      this.msgUtil.warn(this.lbs.m.formInvalido);
      return false;
    }
    if (this.itemSelecionado.foto == null) {
      this.msgUtil.warn('Adicione a Foto do EXAME.');
      return false;
    }
    // if (this.cfg.btnAnexo() && this.itemSelecionado.anexo == null) {
    //   this.msgUtil.warn('Adicione o ANEXO do Documento.');
    //   return false;
    // }
    return true;
  }

  //prettier-ignore
  carregarConfig(): void {    
    /**VAI CONTROLAR OS CAMPOS QUE SERÃO ESCONDIDOS/MOSTRADOS NA FORMA DE TEXTO OU EM INPUTS: NULL (ESCONDE), TRUE (MOSTRA INPUT), FALSE (MOSTRA TEXTO)*/
    this.cfg = {
      /*BOTAO*/
      btnPreencher: (item: ExameCandidatoEdit) => item.idExameVaga != null && item.idSituacaoExame != PropriedadesRh.SITUACAOEXAME_REALIZADO && item.idSituacaoExame != PropriedadesRh.SITUACAOEXAME_VALIDADO && item.idSituacaoExame != PropriedadesRh.SITUACAOEXAME_REJEITADO,
      btnCorrigir: (item: ExameCandidatoEdit) => item.idExameVaga != null && item.idSituacaoExame == PropriedadesRh.SITUACAOEXAME_REJEITADO,
      // btnAnexo: () => this.itemSelecionado.tipoExame.anexo,
      /*INPUT*/
      // anexo: () => this.itemSelecionado.tipoExame.anexo,
      /*CONDICAO*/
      msgAguardando: (item: ExameCandidatoEdit) => item.idExameVaga != null && item.idSituacaoExame == PropriedadesRh.SITUACAOEXAME_REALIZADO,
      msgAprovado: (item: ExameCandidatoEdit) => item.idExameVaga != null && item.idSituacaoExame == PropriedadesRh.SITUACAOEXAME_VALIDADO,
      /**... */
    };
  }

  //FOTO

  selecionarFoto(event) {
    let file = event.target.files[0];
    let reader = new FileReader();
    reader.onloadend = () => {
      this.resizeImage(file, 800, 800, (name, result?) => {
        this.itemSelecionado.foto = result || reader.result;
        this.itemSelecionado.fotoName = name || file.name;
      });
    };
    reader.readAsDataURL(file);
  }

  // selecionarAnexo(event) {
  //   let file = event.target.files[0];
  //   if (file.size > this.maxSizeAnexo) {
  //     this.msgUtil.warn(
  //       'O tamanho do arquivo é maior que 5MB, favor inserir um documento no tamanho determinado.'
  //     );
  //     return;
  //   }
  //   let reader = new FileReader();
  //   reader.readAsDataURL(file);
  //   reader.onload = () => {
  //     this.itemSelecionado.anexo = reader.result;
  //     this.itemSelecionado.anexoName = file.name;
  //   };
  // }

  resizeImage(
    file: File,
    maxWidth: number,
    maxHeight: number,
    callback: (name: string, result?: string | ArrayBuffer) => void
  ) {
    let image = new Image();
    image.src = URL.createObjectURL(file);
    image.onload = () => {
      let width = image.width;
      let height = image.height;
      if (width <= maxWidth && height <= maxHeight) {
        callback(file.name);
      }
      let newWidth;
      let newHeight;
      if (width > height) {
        newHeight = height * (maxWidth / width);
        newWidth = maxWidth;
      } else {
        newWidth = width * (maxHeight / height);
        newHeight = maxHeight;
      }
      let canvas = document.createElement('canvas');
      canvas.width = newWidth;
      canvas.height = newHeight;
      let context = canvas.getContext('2d');
      context.drawImage(image, 0, 0, newWidth, newHeight);
      var finalFile = canvas.toDataURL(file.type);
      let reader2 = new FileReader();
      canvas.toBlob(function (blob) {
        reader2.readAsDataURL(blob);
      }, file.type);
      reader2.onloadend = () => {
        callback(file.name, reader2.result);
      };
    };
  }

  //OUTROS

  voltarParaMenu(): void {
    this.router.navigate(['../menu'], { relativeTo: this.activatedRoute });
  }

  //LOCAL

  private get idVaga(): number {
    return Number.parseInt(sessionStorage.getItem('idVaga'));
  }
}
