import { MessageGrowlService } from './message-growl.service';
import { Component, OnInit, OnDestroy } from '@angular/core';

import { Message } from 'primeng/primeng';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-message-growl',
  templateUrl: './message-growl.component.html',
  styleUrls: ['./message-growl.component.scss']
})
export class MessageGrowlComponent implements OnInit, OnDestroy {

  subscription: Subscription;
  messages: Message[] = [];

  constructor(private messageGrowlService: MessageGrowlService) { }

  ngOnInit() {
    this.subscription = this.messageGrowlService.getSubject().subscribe(retorno => {
      this.messages = retorno.messages;      
    });  
  }

  ngOnDestroy(){
    this.subscription.unsubscribe();
  }

}
