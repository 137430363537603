import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";

import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';
import { ConfigCliente } from 'src/app/_config-cliente/config-cliente';

@Component({
  selector: 'app-frame-jsf',
  templateUrl: './frame-jsf.component.html',
  styleUrls: ['./frame-jsf.component.scss']
})
export class FrameJsfComponent implements OnInit {

  url: SafeResourceUrl;
  subscription: Subscription;
  
  constructor(private activatedRoute: ActivatedRoute, private sanitizer: DomSanitizer, private authService: AuthService) { }

  ngOnInit() {
    this.url = '';
    this.subscription = this.activatedRoute.params.subscribe(
      next => {
        this.url = this.sanitizer.bypassSecurityTrustResourceUrl(ConfigCliente.APP_MAIN + next['url'] + '?t=' + localStorage.getItem('token') + '&u=' + localStorage.getItem('idUnidadeAtiva'));
        //this.url = this.sanitizer.bypassSecurityTrustResourceUrl(next['url']);
    });
  }

}
