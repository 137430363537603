import { ViewMode } from 'src/app/core/constant/view-mode.enum';
import { CandidatoVagaHttpService } from 'src/app/core/modulos/rh/http/candidato-vaga-http.service';
import { map, catchError } from 'rxjs/operators';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { of, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthResolverService implements Resolve<any> {
  constructor(private candidatoVagaHttpService: CandidatoVagaHttpService, private router: Router) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    /**
     * utilizado por enquanto, para controlar quando o usuário efetua o login, se ele é um candidato vaga ativo ou se já é um funcionário contratado.
     */
    const view = sessionStorage.getItem('view'); //PC -> PRÉ-CADASTRO; DF -> DEFAULT
    if (view) {
      if (view == ViewMode.PRECADASTRO) this.router.navigate(['/rh/pre-cadastro/menu']);
      else if (view == ViewMode.DEFAULT) return of(null);
      else this.router.navigate(['/login']);
    } else {
      return this.candidatoVagaHttpService.existeCandidatoAtivo().pipe(
        map((res: any) => {
          console.log(res);
          if (res == true) {
            sessionStorage.setItem('view', ViewMode.PRECADASTRO);
            this.router.navigate(['/rh/pre-cadastro/menu']);
          } else {
            sessionStorage.setItem('view', ViewMode.DEFAULT);
            return of(null);
          }
        }),
        catchError((error) => {
          this.router.navigate(['/login']);
          return throwError(error);
        })
      );
    }
  }

  // constructor(
  //   private router: Router,
  //   private msgUtil: MessageUtilService,
  //   private chamadoHttpService: ChamadoHttpService,
  //   private prioridadeHttpService: PrioridadeHttpService,
  //   private tecnicoHttpService: TecnicoHttpService,
  //   private situacaoChamadoHttpService: SituacaoChamadoHttpService
  // ) {}

  // //prettier-ignore
  // resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
  //   let id = Number.parseInt(route.paramMap.get('id'));
  //   //PRIMEIRO CONSULTA O CHAMADO-EDIT
  //   return this.chamadoHttpService.load(id).pipe(
  //     //PARA EXECUTAR REQUISICOES DEPENDENTES DO ID DO CHAMADO UTILIZÁ-LAS DENTRO DO MERGEMAP
  //     mergeMap((res: any) => {
  //       console.log(res);
  //       const bean: ChamadoEdit = res.retorno;
  //       const ob0 = of(res.retorno);
  //       const ob1 = bean.estacaoOption?.idEstacao ? this.prioridadeHttpService.listarOptionsPorContrato(bean.estacaoOption.idContrato, bean.estacaoOption.idEstacao) :of(null) ;
  //       const ob2 = bean.estacaoOption?.idEstacao ? this.tecnicoHttpService.listarGenericOptionPorContrato(bean.estacaoOption.idContrato,bean.estacaoOption.idMacroarea) : of(null) ;
  //       const ob3 = this.situacaoChamadoHttpService.listarGenericOptions();
  //       const ob4 = this.chamadoHttpService.listarCometariosChamado(id);
  //       const ob5 = this.chamadoHttpService.listarHistoricoTransferencia(id);
  //       const ob6 = this.chamadoHttpService.listarAnexosChamado(id);
  //       return forkJoin([ob0, ob1, ob2, ob3, ob4, ob5, ob6]);
  //     }),
  //     catchError((error) => {
  //       //PODE GERAR ERRO NO CASO DE NÃO TER PERMISSÃO NESTE CHAMADO
  //       this.router.navigate(['/chm/chamado']);
  //       this.msgUtil.mostrarMensagem(2, error, true, true);
  //       return of(null)
  //     })
  //   );
  // }
}
