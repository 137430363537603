import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Labels } from 'src/app/core/constant/labels';

@Component({
  selector: 'app-lancamento',
  templateUrl: './lancamento.component.html',
  styleUrls: ['./lancamento.component.scss'],
})
export class LancamentoComponent implements OnInit {
  constructor(public lbs: Labels, private router: Router) {}

  ngOnInit() {
    this.router.navigate(['/rh/lancamento/list']);
  }
}
