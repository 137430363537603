import { ObjectUtilService } from './../../_util/object-util/object-util.service';
import { AuthService } from './../auth.service';
import { Injectable } from '@angular/core';
import { CanActivate, CanLoad, ActivatedRouteSnapshot, RouterStateSnapshot, Router, Route } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable()
export class AuthGuard implements CanActivate, CanLoad {
  constructor(private router: Router, private AuthService: AuthService) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.verificarAcesso(state.url);
  }

  canLoad(route: Route): Observable<boolean> | Promise<boolean> | boolean {
    return this.verificarAcesso(null);
  }

  private verificarAcesso(url: string): Observable<boolean> | Promise<boolean> | boolean {
    /**corrigir esse método para que ele só retorne seu valor quando o método HTTP terminar de executar!!! */
    if (this.AuthService.loggedIn()) {
      if (!ObjectUtilService.contains(url, ['/recuperar-senha', '/login', '/'])) {
        let r = this.AuthService.atualizarSessao().subscribe(
          //para chamadas HTTP é utilizado subscribe
          (data) => {
            localStorage.setItem('token', data); //guarda o token no localStorage
            return true;
          },
          (error) => {
            this.router.navigate(['/login']);
            return false;
          },
          () => {}
        );
      }
      return true;
    }
    this.router.navigate(['/login']);
    return false;
  }
}
