import { Subscription } from 'rxjs';
import { LoadingService } from 'src/app/_util/loading/loading.service';
import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent {
  title = 'arenago-pwa';
  //vai indicar quanda a layer de bloqueio da página deve ser mostrada ou oculada, seu valor será preenchido pela transmissão do servico do loadingService
  blocked: boolean = false;
  subscription: Subscription;

  constructor(public loadingService: LoadingService) { }

  ngOnInit() {
    this.listenerOnLoading();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  listenerOnLoading(): void {
    this.subscription = this.loadingService.loading$.subscribe(
      (next: boolean) => {
        //alert('listenerOnLoading');
        //QUANDO O VALOR DO LOADING NO LOADINGSERVICE FOR ALTERADO PELO AUTHINTERCEPTOR, INDICANDO O PROCESSAMENTO DE UM REQUEST, O VALOR DO BLOCKED SERÁ ALTEADO.
        this.blocked = next;
        console.log('blocked: ' + this.blocked);
        
      });
  }

}
