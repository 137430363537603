import { Injectable } from '@angular/core';
import { LancamentoService } from 'src/app/m_rh/services/lancamento.service';
import { Router, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LancamentoEditResolverService {

  constructor(private lancamentoService: LancamentoService, private router: Router) {}
  
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any | Observable<any> | Promise<any> {
    let id: any = route.paramMap.get('id');
    return this.lancamentoService.load(id);
  }

}
