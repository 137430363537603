import { RetornoResponse } from 'src/app/core/model/retorno-response';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { Path } from 'src/app/core/constant/path';

@Injectable({
  providedIn: 'root',
})
export class RhAlterarSenhaService {
  constructor(private httpClient: HttpClient) {}

  jsonToParams(parametros: any): HttpParams {
    //como: HttpParams is intended to be immutable. The set and append methods don't modify the existing instance. Instead they return new instances
    //para funcionar o for é necessário atribuir novamente o valor do param a cada for (semelhante ao BigDecimal no java).
    let params: HttpParams = new HttpParams();
    for (let key in parametros) {
      params = params.set(key, parametros[key]);
    }
    return params;
  }

  alterarSenha1() {
    const p: HttpParams = this.jsonToParams({ alterar: false });
    return this.httpClient.get<RetornoResponse>(Path.ACS_USUARIO_RH_ALTERAR_SENHA, { params: p }).pipe(
      //com o httpClient por padrão é retornado um json, e se quiser definir seu tipo é colocado entre <> após o get ou feito um cast dentro do retorno.
      map((res) => {
        localStorage.setItem('chaveAlterarSenha', res.retorno);
        return res;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  alterarSenha2(senhaAtual: string, senhaNova: string) {
    const p: HttpParams = this.jsonToParams({
      senhaAtual: senhaAtual,
      senhaNova: senhaNova,
      chaveAlterarSenha: localStorage.getItem('chaveAlterarSenha'),
      alterar: true,
    });
    return this.httpClient.get<RetornoResponse>(Path.ACS_USUARIO_RH_ALTERAR_SENHA, { params: p }).pipe(
      //com o httpClient por padrão é retornado um json, e se quiser definir seu tipo é colocado entre <> após o get ou feito um cast dentro do retorno.
      map((res) => {
        if (res.codigo == 1) throw res.mensagem;
        return res;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }
}
