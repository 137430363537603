import { AbstractControl, FormGroup, Validators } from '@angular/forms';
import { Injectable } from '@angular/core';

@Injectable()
export class FormUtil {
  constructor() {}

  /**form = bean; Atualiza o FORM com os valores do BEAN; Para que essa função funcione os campos do bean que serão controlados no form devem possuir o MESMO NOME do formControlName*/
  public static setBeanToForm(form: FormGroup, bean: any): void {
    // console.log('');
    // console.log('-> setBeanToForm');
    Object.keys(form.controls).forEach((e) => {
      // console.log(e + ': ' + bean[e]);
      form.controls[e].setValue(bean[e]);
    });
    // console.log('');
  }

  /**BEAN = FORM; ATUALIZA O BEAN COM OS VALORES DO FORM; Para que essa função funcione os campos do bean que serão controlados no form devem possuir o MESMO NOME do formControlName*/
  public static setFormToBean(form: FormGroup, bean: any): void {
    // console.log('');
    // console.log('-> setFormToBean');
    Object.keys(form.controls).forEach((e) => {
      console.log(e + ': ' + form.controls[e].value);
      //@PATRICIA: IF ADICIONADO PARA ATRIBUIR SOMENTE AS PROPRIEDADES QUE O BEAN POSSUIR, IGNORANDO OS FORMCONTROLNAME'S QUE POSSUIR.
      //if (bean.hasOwnProperty(e)) {
      bean[e] = form.controls[e].value;
      //} else {
      //console.log('bean não possui a propriedade: ' + e);
      //}
    });
    // console.log('');
  }

  /**form = beanFiltro; Atualiza o FORM com os valores do BEAN filtro; Para que essa função funcione os campos do bean que serão controlados no form devem possuir o MESMO NOME do formControlName*/
  public static setFiltroToForm(form: FormGroup, filtro: any): void {
    Object.keys(form.controls).forEach((e) => {
      //SE POSSUIR _ INDICA QUE É UM PERÍODO E FAZ PARTE DE UM ARRAY DE DATE, EXEMPLO, PERIODO_0 PERIODO_1, REPRESENTANDO UM ARRAY DE PERIODO (DATE[])
      if (e.indexOf('_') != -1) {
        form.controls[e].setValue(filtro[e.split('_')[0]][e.split('_')[1]]);
      } else {
        form.controls[e].setValue(filtro[e]);
      }
    });
  }

  /**bean = form; Atualiza o BEAN do form com os valores do FORM; Para que essa função funcione os campos do bean que serão controlados no form devem possuir o MESMO NOME do formControlName*/
  public static setFormToFiltro(form: FormGroup, filtro: any): void {
    Object.keys(form.controls).forEach((e) => {
      //SE POSSUIR _ INDICA QUE É UM PERÍODO E FAZ PARTE DE UM ARRAY DE DATE, EXEMPLO, PERIODO_0 PERIODO_1, REPRESENTANDO UM ARRAY DE PERIODO (DATE[])
      if (e.indexOf('_') != -1) {
        filtro[e.split('_')[0]][e.split('_')[1]] = form.controls[e].value;
      } else {
        filtro[e] = form.controls[e].value;
      }
    });
  }

  public static markAllAsDirty(form: FormGroup): void {
    Object.keys(form.controls).forEach((e) => {
      form.controls[e].markAsDirty();
      //UTILIZADO QUANDO O CONTROL É UM FORMGROUP (SUBDIVISÃO DO FORMULÁRIO)
      if (form.controls[e] instanceof FormGroup) {
        let subForm: FormGroup = form.controls[e] as FormGroup;
        console.log(subForm.controls);
        this.markAllAsDirty(subForm);
      }
    });
  }

  public static cleanAllMaks(form: FormGroup): void {
    Object.keys(form.controls).forEach((e) => {
      form.controls[e].markAsPristine();
      form.controls[e].markAsUntouched();
    });
  }

  public static addRequired(form: FormGroup | AbstractControl, controls: string[]) {
    controls.forEach((formControlName: string) => {
      form.get(formControlName).setValidators([Validators.required]); //futuramente quando migrar para o angular 12, alterar para o "addValidators"
      form.get(formControlName).updateValueAndValidity();
    });
  }

  public static removeRequired(form: FormGroup | AbstractControl, controls: string[]) {
    controls.forEach((formControlName: string) => {
      form.get(formControlName).clearValidators();
      form.get(formControlName).updateValueAndValidity();
    });
  }

  public static resetControls(form: FormGroup, controls: string[]) {
    controls.forEach((formControlName: string) => {
      form.get(formControlName).reset();
    });
  }

  //public static clearValidators(form: FormGroup, controls: string[]) {
  public static clearValidators(form: FormGroup | AbstractControl, controls: string[]) {
    controls.forEach((formControlName: string) => {
      form.get(formControlName).clearValidators();
      form.get(formControlName).updateValueAndValidity();
    });
  }

  /**
   * VAI PERCORRER OS FORM CONTROLS E SINCRONIZAR O EVENTO DE VALUECHANGE DE CADA CONTROL A PROPRIEDADE DE BEAN DE MESMO NOME
   */
  public static carregarFormValueChanges(form: FormGroup, bean: any): void {
    //VAI MONITORAR INDIVIDUALMENTE OS VALORES ALTERADOS DOS CONTROLS QUE TIVEREM RELAÇÃO COM O BEAN, MANTENDO O BEAN ATUALIZADO
    Object.keys(form.controls).forEach((e) => {
      if (bean.hasOwnProperty(e)) {
        form.get(e).valueChanges.subscribe((value) => {
          bean[e] = value;
        });
      }
    });
  }
}
