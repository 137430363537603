import { RetornoResponse } from 'src/app/core/model/retorno-response';
import { Path } from 'src/app/core/constant/path';
import { ParamUtilService } from './../_util/param-util/param-util.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

@Injectable()
export class MenuService {
  private subject: Subject<any> = new Subject<any>();

  constructor(private httpClient: HttpClient, private paramUtilService: ParamUtilService) {}

  getSubject(): Observable<any> {
    return this.subject.asObservable();
  }

  listarMenu(idUnidade: any) {
    //const p: HttpParams = idUnidade == null ? null : this.paramUtilService.jsonToParams({idUnidade: idUnidade});
    const p: HttpParams = this.paramUtilService.jsonToParams({ idUnidade: idUnidade, menu: 2 });
    localStorage.setItem(
      'idUnidadeAtiva',
      idUnidade
    ); /*Guardar idUnidadeAtiva para ser enviada ao iframe quando páginas jsp e jsf forem acessadas*/
    return this.httpClient.get<RetornoResponse>(Path.ACS_USUARIO_CARREGARMENU, { params: p }).pipe(
      map((res) => {
        this.subject.next({ aplicativos: res.retorno });
        return res.retorno;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  listarUnidade() {
    return this.httpClient.get<RetornoResponse>(Path.ACS_USUARIO_LISTARUNIDADE).pipe(
      map((res) => {
        return res.retorno;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }
}
