import { Labels } from '../../core/constant/labels';
import { SelectItem } from 'primeng/primeng';
import { Injectable } from '@angular/core';

/**Vai manipular listar de objetos e transforma-las em objetos que podem ser usado dentro de dropdowns e outros */
@Injectable()
export class InputUtilService {
  constructor(private lbs: Labels) {}

  /**vai transformar um lista de objetos em um lista de SelectItem com {label: optionLabel, value: [object]} para poderem ser usado em dropdown e afins
   * @param lista json/dados que deverão ser carregados nos options
   * @param optionLabel atributo que será o mostrado no label do option
   */
  toSelectItem(lista: any[], optionLabel: string): SelectItem[] {
    let selectItens: SelectItem[] = [];
    lista.forEach((e) => {
      selectItens.push({ label: eval(`e.${optionLabel}`), value: e });
    });
    return selectItens;
  }

  /**vai transformar um lista de objetos em um lista de SelectItem com {label: optionLabel, value: id} para poderem ser usado em dropdown e afins, o value será o ID dos objetos.
   * @param lista dados que deverão ser carregados nos options
   * @param optionLabel atributo que será o mostrado no label do option
   * @param optionTodos vai definir se deve adicionar um option "todas opções", value desse option será NULL
   */
  toSelectItemId(lista: any[], optionLabel: string, optionTodos: boolean): SelectItem[] {
    return this._toSelectItemId(lista, optionLabel, optionTodos, null);
  }

  /**vai transformar um lista de objetos em um lista de SelectItem com {label: optionLabel, value: id} para poderem ser usado em dropdown e afins
   * @param lista dados que deverão ser carregados nos options
   * @param optionLabel atributo que será o mostrado no label do option
   * @param optionTodos vai definir se deve adicionar um option "todas opções"
   * @param optionTodosValue defini qual será o VALUE da opção "todas opções"
   */
  toSelectItemId0(lista: any[], optionLabel: string, optionTodos: boolean, optionTodosValue: any): SelectItem[] {
    return this._toSelectItemId(lista, optionLabel, optionTodos, optionTodosValue);
  }

  private _toSelectItemId(
    lista: any[],
    optionLabel: string,
    optionTodos: boolean,
    optionTodosValue: any
  ): SelectItem[] {
    let selectItens: SelectItem[] = [];
    if (optionTodos) selectItens.push({ label: this.lbs.m.todasOpcoes, value: optionTodosValue });
    lista.forEach((e) => {
      selectItens.push({ label: eval(`e.${optionLabel}`), value: e.id });
    });
    return selectItens;
  }
}
