import { CandidatoVagaHttpService } from 'src/app/core/modulos/rh/http/candidato-vaga-http.service';
import { Injectable } from '@angular/core';
import { Resolve, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';

@Injectable()
export class MenuPreCadastroResolverService implements Resolve<any> {
  constructor(private candidatoVagaHttpService: CandidatoVagaHttpService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.candidatoVagaHttpService.loadProgressoCandidato();
  }
}
