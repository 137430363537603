import { Mensagens } from 'src/app/core/constant/messages';
import { HashLocationStrategy, LocationStrategy, registerLocaleData } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutes } from './app.routes';
import { AuthInterceptor } from './auth/auth.interceptor';
import { AuthModule } from './auth/auth.module';
import { MenuService } from './menu/menu.service';
import { Labels } from './core/constant/labels';
import { UtilModule } from './_util/util.module';


import { CalendarModule } from 'primeng/calendar';

import { CheckboxModule } from 'primeng/checkbox';

import { PasswordModule } from 'primeng/password';

import { TabViewModule } from 'primeng/tabview';


import { AppBreadcrumbComponent } from './app.breadcrumb.component';
import { AppComponent } from './app.component';
import { AppConfigComponent } from './app.config.component';
import { AppFooterComponent } from './app.footer.component';
import { AppMainComponent } from './app.main.component';
import { AppMenuComponent, AppSubMenuComponent } from './app.menu.component';
import { AppRightPanelComponent } from './app.rightpanel.component';
import { AppTopBarComponent } from './app.topbar.component';
import { AppLoginComponent } from './auth/login/app.login.component';
import { AppAccessdeniedComponent } from './pages/app.accessdenied.component';
import { AppErrorComponent } from './pages/app.error.component';
import { AppNotfoundComponent } from './pages/app.notfound.component';

import localeBr from '@angular/common/locales/pt';
import { ServiceWorkerModule } from '@angular/service-worker';
import { ConfirmationService } from 'primeng/api';
import { BlockUIModule } from "primeng/blockui";
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { environment } from '../environments/environment';
import { BreadcrumbService } from './breadcrumb.service';
import { PaginasEstoqueModule } from './m_estoque/paginas-estoque.module';
import { PaginasRhModule } from './m_rh/paginas-rh.module';
import { RecuperarSenhaComponent } from './auth/recuperar-senha/recuperar-senha.component';
import { AlterarSenhaComponent } from './auth/alterar-senha/alterar-senha.component';
import { InputMask, InputMaskModule } from 'primeng/primeng';

registerLocaleData(localeBr, 'pt');

@NgModule({
    schemas:[],
    imports: [
        BrowserModule,
        FormsModule,
        ReactiveFormsModule,
		    //AppRoutes,
        HttpClientModule,
        BlockUIModule,
        /*PRIMENG*/

        ConfirmDialogModule,
        BrowserAnimationsModule, //no excluir
        CalendarModule,
        CheckboxModule,
        PasswordModule,
        TabViewModule,
        InputMaskModule,

        /*ROUTES*/        
        AppRoutes,
        
        /*MY MODULES*/
        AuthModule,
        UtilModule,
        PaginasRhModule,
        PaginasEstoqueModule,
        ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
    ],
    declarations: [
        AppComponent,
        AppMainComponent,
        AppMenuComponent,
        AppSubMenuComponent,
        AppTopBarComponent,
        AppFooterComponent,
        AppRightPanelComponent,
        AppConfigComponent,
        AppBreadcrumbComponent,
        //DashboardDemoComponent,

        AppNotfoundComponent,
        AppErrorComponent,
        AppAccessdeniedComponent,
        AppLoginComponent,
        RecuperarSenhaComponent,
        AlterarSenhaComponent
        
    ],
    providers: [
        {provide: LocationStrategy, useClass: HashLocationStrategy},
        /**primeng teste*/
        BreadcrumbService,
        ConfirmationService,
        /** */

        /*ADICIONEI PARA DEFINIR O LOCALE DO PIPE 'CURRENCY'*/
        {provide: LOCALE_ID, useValue: 'pt'},

        /*MY INTERCEPTORS*/
        {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
        
        /*MY SERVICES*/
        MenuService,

        /*MY CONSTANTS*/
        Mensagens,
        ConfirmationService,
        // MessageService,
        Labels 
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
