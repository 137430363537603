import { Labels } from 'src/app/core/constant/labels';
import { FormGroup, AbstractControl } from '@angular/forms';
import { Component, Input, OnInit } from '@angular/core';

/**
 * @patricia
 * customiza o p-message do primeng para mostrar mensagens de validação dos inputs de required value, invalid value.
 * form - propriedade obrigatória, informando o form do input.
 * name - propriedade obrigatória, o valor do formControlName do input.
 * invalidMessage - utilizado somente quando houver necessidade de mostrar alguma mensagem de validação. Por enquanto é geral, posteriormente pode-se melhorar para mostrar a mensagem de acordo com a regra de validação.
 */

@Component({
  selector: 'app-message-input',
  templateUrl: './message-input.component.html',
  styleUrls: ['./message-input.component.scss'],
})
export class MessageInputComponent implements OnInit {
  constructor(public lbs: Labels) {}

  @Input() form: FormGroup;
  @Input() name: string;
  @Input() invalidMessage: string;

  ngOnInit(): void {
    this.invalidMessage = this.invalidMessage || this.lbs.m.valorInvalido; //this.invalidMessage ?? this.lbs.m.valorInvalido;
  }

  mostrarRequired(): boolean {
    if (this.form == null || this.form.controls[this.name] == undefined) return false;
    let mostrar: boolean =
      this.form.controls[this.name].invalid &&
      this.form.controls[this.name].value != null &&
      this.form.controls[this.name].dirty &&
      this._isRequired();
    //this.name == 'estacaoOption' && console.log(this.name + '-> mostrarRequired: ' + mostrar);
    return mostrar;
  }

  mostrarInvalidValue(): boolean {
    if (this.form == null || this.form.controls[this.name] == undefined) return false;
    let mostrar: boolean =
      this.form.controls[this.name].invalid &&
      this.form.controls[this.name].value != null &&
      this.form.controls[this.name].dirty;
    //this.name == 'estacaoOption' && console.log(this.name + '-> mostrarInvalidValue: ' + mostrar);
    return mostrar;
  }

  private _isRequired(): boolean {
    const validator = this.form.get(this.name).validator({} as AbstractControl);
    return validator && validator.required;
  }
}
