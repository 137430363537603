import { HttpClient } from '@angular/common/http';
import { ParamUtilService } from 'src/app/_util/param-util/param-util.service';
import { AbstractGenericHttpService } from 'src/app/core/service/abstract-generic-http.service';
import { Path } from 'src/app/core/constant/path';
import { Injectable } from '@angular/core';

const PATH = Path.TBL + 'grau-parentesco/';
const URL_LISTAR_GENERICOPTIONS = PATH + 'generic-options';

@Injectable({
  providedIn: 'root',
})
export class GrauParentescoHttpService extends AbstractGenericHttpService {
  constructor(protected httpClient: HttpClient, private paramUtil: ParamUtilService) {
    super(httpClient);
  }

  listarGenericOptions() {
    return this._doGetRetorno(URL_LISTAR_GENERICOPTIONS);
  }
}
