import { Labels } from './../../../core/constant/labels';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pre-cadastro',
  templateUrl: './pre-cadastro.component.html',
  styleUrls: ['./pre-cadastro.component.scss'],
})
export class PreCadastroComponent implements OnInit {
  constructor(private router: Router, private route: ActivatedRoute, public lbs: Labels) {}

  ngOnInit() {
    this.router.navigate(['menu'], { relativeTo: this.route });
  }
}
