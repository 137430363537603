import { ObjectUtil } from 'src/app/util/service/object-util.service';
import { RetornoResponse } from 'src/app/core/model/retorno-response';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, SelectItem } from 'primeng/primeng';
import { Subscription } from 'rxjs';
import { Labels } from 'src/app/core/constant/labels';
import { CandidatoVagaHttpService } from 'src/app/core/modulos/rh/http/candidato-vaga-http.service';
import { PropriedadesTabela } from 'src/app/core/modulos/tabela/constant/propriedades-tabela';
import { CidadeHttpService } from 'src/app/core/modulos/tabela/http/cidade-http.service';
import { CandidatoVagaEdit } from 'src/app/m_rh/pages/pre-cadastro/model/candidato-vaga-edit';
import { InputUtilService } from 'src/app/_util/input-util/input-util.service';
import { LoadingService } from 'src/app/_util/loading/loading.service';
import { DadosCadastraisResolverService } from './../service/dados-cadastrais-resolver.service';
import { FormUtil } from 'src/app/util/service/form-util.service';
import { MessageUtilService } from 'src/app/util/service/message-util.service';

@Component({
  selector: 'app-dados-cadastrais',
  templateUrl: './dados-cadastrais.component.html',
  styleUrls: ['./dados-cadastrais.component.scss'],
})
export class DadosCadastraisComponent implements OnInit {
  bean: CandidatoVagaEdit;
  candidatoSessao: any;
  cfg: any = {};
  form: FormGroup;
  passos: boolean[] = [true, false, false, false, false, false]; //[true, false, false, false, false]; // 6 PASSOS
  //prettier-ignore
  passosFormRequired: string[][] = [//CAMPOS OBRIGATÓRIO EM CADA PASSO, UTILIZADO PARA VERIFICAR SE O USUÁRIO PODE AVANÇAR PARA O PRÓXIMO PASSO.
    ['pessoa.nome', 'pessoa.cpfCnpj', 'pessoa.dataNascimento', 'telefone.numero'],
    ['pessoa.nacionalidade', 'pessoa.cidadeNascimento', 'pessoa.sexo', 'pessoa.racaCor', 'pessoa.pcd', 'pessoa.grauInstrucao'],
    ['pessoa.nomeMae', 'pessoa.estadoCivil', 'dadosCandidato.possuiFilhos', 'dadosCandidato.tamanhoSapato', 'dadosCandidato.tamanhoCamisa', 'dadosCandidato.tamanhoCalca'],
    ['endereco.tipoLogradouro', 'endereco.logradouro', 'endereco.numero', 'endereco.complemento', 'endereco.cidade', 'endereco.bairroNome', 'endereco.cep'] ,
    ['dadosCandidato.banco', 'dadosCandidato.tipoContaBanco', 'dadosCandidato.agenciaBanco', 'dadosCandidato.contaBanco'],
  ];
  subscription: Subscription = new Subscription();

  optionsBanco: SelectItem[] = [];
  optionsCidade: SelectItem[] = [];
  optionsEstado: SelectItem[] = [];
  optionsEstadoCivil: SelectItem[] = [];
  optionsGrauInstrucao: SelectItem[] = [];
  optionsPais: SelectItem[] = [];
  optionsRacaCor: SelectItem[] = [];
  optionsSexo: SelectItem[] = [];
  optionsBeneficios: SelectItem[] = [];
  optionsSimNao: SelectItem[] = PropriedadesTabela.SIMNAO;
  optionsVrVl: SelectItem[] = [
    { label: 'Alimentação', value: 1 },
    { label: 'Refeição', value: 2 },
  ];
  optionsModo: SelectItem[] = [
    { label: 'ida', value: 'ida' },
    // { label: 'ida e volta', value: 'ida e volta' },
    { label: 'volta', value: 'volta' },
  ];

  optionsTipoLogradouro: SelectItem[] = [];
  optionsTipoPcd: SelectItem[] = [];
  optionsTipoContaBanco: SelectItem[] = [];
  formPassagem: FormGroup;
  GRUPO_BENEFICIO = { VA: 1, VR: 2, TRANSPORTE: 3, CESTA: 4 };
  constructor(
    public lbs: Labels,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private inputUtil: InputUtilService,
    public msgUtil: MessageUtilService,
    private loadingService: LoadingService,
    private confirmationService: ConfirmationService,
    private cidadeHttpService: CidadeHttpService,
    private candidatoVagaHttpService: CandidatoVagaHttpService
  ) {}

  ngOnInit() {
    // this.listarOptionsPais();
    //this.carregarForm();
    this.carregarPagina();
  }

  /*LÓGICA DE CARREGAMENTO DA PÁGINA*/
  carregarPagina(): void {
    this.loadingService.off();
    this.subscription.add(
      this.activatedRoute.data.subscribe(
        (data: { dataResolver: any }) => {
          this._listarOptionsBanco(data);
          this._listarOptionsEstado(data);
          this._listarOptionsEstadoCivil(data);
          this._listarOptionsGrauInstrucao(data);
          this._listarOptionsPais(data);
          this._listarOptionsRacaCor(data);
          this.optionsSexo = this.inputUtil.toSelectItem(PropriedadesTabela.SEXO, 'label');
          this.optionsBeneficios = this.inputUtil.toSelectItem(PropriedadesTabela.BENEFICIOS, 'label');
          this._listarOptionsTipoPcd(data);
          this._listarOptionsTipoContaBanco(data);
          this._listarOptionsTipoLogradouro(data);
          //console.log(data);

          this.candidatoSessao = data.dataResolver[DadosCadastraisResolverService.CANDIDATOSESSAO];
          //console.log(this.candidatoSessao);

          //this.loadingService.off();
          this.loadingService.onLoading(false);
          this.carregarForm();
        },
        (error) => {
          this.loadingService.off();
        }
      )
    );
  }

  voltarPasso(anterior: number): void {
    this.msgUtil.limpar();
    this.passos = [false, false, false];
    this.passos[anterior] = true;
    sessionStorage.setItem('dadosCadastrais', JSON.stringify(this.form.value));
  }

  avancarPasso(proximo: number): void {
    this.msgUtil.limpar();
    const passoAtual = proximo - 1;
    if (this._validarFomularioPasso(passoAtual)) {
      this.passos = [false, false, false, false, false];
      this.passos[proximo] = true;
      sessionStorage.setItem('dadosCadastrais', JSON.stringify(this.form.value));
    }
  }

  concluir(): void {
    this.confirmationService.confirm({
      message:
        'Ao concluir o preenchimento dos DADOS CADASTRAIS, você não poderá mais alterá-los. Tem certeza que os dados foram preenchidos corretamente?',
      accept: () => {
        this._salvarDadosCadastrais();
      },
    });
  }

  //FORM

  //prettier-ignore
  carregarForm(): void {
    this.form = this.formBuilder.group({ //PARA A INTEGRAÇÃO ENTRE O BEAN E OS CONTROLS FUNCIONAREM O FORMCONTROLNAME DEVEM POSSUIR O MESMO NOME DO CAMPO DO BEAN AO QUAL ELE CORRESPONDE.
      pessoa: this.formBuilder.group({
        sexo: [null, [Validators.required]],
        grauInstrucao: [null, [Validators.required]],
        estadoCivil: [null, [Validators.required]],
        racaCor: [null, [Validators.required]],
        nacionalidade: [null, [Validators.required]],
        cpfCnpj: [null, [Validators.required]],
        nome: [null, [Validators.required, Validators.maxLength(150)]],
        nomeMae: [null, [Validators.required, Validators.maxLength(150)]],
        nomePai: [null],
        dataNascimento: [null, [Validators.required]],
        //estadoNascimento: [null, [Validators.required]],
        cidadeNascimento: [null, [Validators.required]],
        pcd: [null, [Validators.required]],
        //pcdObservacao: [null],
        tipoPcd: [null],
        pcdObservacao: [null],

        
        //local
      }),
    
      endereco: this.formBuilder.group({
        tipoLogradouro: [null, [Validators.required]],
        cidade: [null, [Validators.required]],
        bairroNome: [null, [Validators.required, Validators.maxLength(200)]],
        cep: [null, [Validators.required]],
        logradouro: [null, [Validators.required, Validators.maxLength(150)]],
        numero: [null, [Validators.required, Validators.maxLength(30)]],
        complemento: [null, [Validators.required, Validators.maxLength(150)]],
        referencia: [null, [Validators.maxLength(100)]],

        //local
        estado: [null],
      }),
    
      telefone: this.formBuilder.group({
        numero: [null, [Validators.required, Validators.maxLength(20)]],
      }),

      email: this.formBuilder.group({
        endereco: [null, [Validators.maxLength(150)]],
      }),
    
      // documentoPis: this.formBuilder.group({
      //   numero: [null, [Validators.required]],
      // }),
    
      // documentoRg: this.formBuilder.group({
      //   numero: [null, [Validators.required]],
      //   dataEmissao: [null, [Validators.required]],
      // }),
    
      // documentoCtps: this.formBuilder.group({
      //   numero: [null, [Validators.required]],
      //   serie: [null, [Validators.required]],
      //   estadoEmissao: [null, [Validators.required]],
      //   dataEmissao: [null, [Validators.required]],
      // }),
    
      // documentoCnh: this.formBuilder.group({
      //   numero: [null],
      //   complemento: [null],
      //   dataValidade: [null],
      // }),

      dadosCandidato: this.formBuilder.group({
        tamanhoSapato: [null, [Validators.required, Validators.maxLength(10)]],
        tamanhoCamisa: [null, [Validators.required, Validators.maxLength(10)]],
        tamanhoCalca: [null, [Validators.required, Validators.maxLength(10)]],
        banco: [null, [Validators.required]],
        tipoContaBanco: [null, [Validators.required]],
        agenciaBanco: [null, [Validators.required, Validators.maxLength(25)]],
        contaBanco: [null, [Validators.required, Validators.maxLength(25)]],
        possuiFilhos: [null, [Validators.required]],
        tipoBeneficioVale: [null, [Validators.required]],
        necessitaValeTransporte:[false],
        beneficioTransporte:this.formBuilder.group({id:[null],idGrupoBeneficio:[null],descricaoItens:[null],valor:[null]}),
      }),

    });
    let dadosCandidato=this.form.get('dadosCandidato');

    if(sessionStorage.getItem('dadosCadastrais')){
      this.form.setValue(JSON.parse(sessionStorage.getItem('dadosCadastrais')));
    }

    if(this.form.value.pessoa.cpfCnpj == null){
      //NO CASO DE CORREÇÃO TODOS OS DADOS SERÃO PREENCHIDO.
      if(this.candidatoSessao.correcao){
        this.form.patchValue(this.candidatoSessao);
      }else{
        this.form.patchValue({
          pessoa: {
            cpfCnpj: this.candidatoSessao.cpf,
            nome: this.candidatoSessao.nome
          }
        });
      }
    }

    //VAI MONITORAR INDIVIDUALMENTE OS VALORES ALTERADOS DOS CONTROLS QUE TIVEREM RELAÇÃO COM O BEAN, MANTENDO O BEAN ATUALIZADO
    Object.keys(this.form.controls).forEach((e) => {
      this._formValueChanges(this.form.get(e));
    });
    this.carregarFormPassagem();
  }
  carregarFormPassagem() {
    this.formPassagem = this.formBuilder.group({
      linha: [null, [Validators.required]],
      empresa: [null, [Validators.required]],
      valor: [(1).toFixed(2), [Validators.required]],
      modo: ['ida', Validators.required],
    });
    let valorCampo = this.formPassagem.get('valor');
    /*
    valorCampo.valueChanges.subscribe((value) => {
      if (value <= 0) value = 0.01;
      if (value > 9999.99) value = 9999.99;
      valorCampo.setValue((Math.round(value * 100) / 100).toFixed(2), { emitEvent: false });
    });*/
  }
  adicionarLinha() {
    let dadosCandidato = this.form.get('dadosCandidato');
    let beneficioTransporte = dadosCandidato.get('beneficioTransporte');
    let beneficio = beneficioTransporte.value;
    let passagem = this.formPassagem.value;
    if (passagem.modo == null || passagem.modo.length < 3) {
      window.scrollTo(0, 0);
      this.msgUtil.warn('Informe um modo de trajeto válido!');
      return;
    }

    if (passagem.linha == null || passagem.linha.length < 3) {
      window.scrollTo(0, 0);
      this.msgUtil.warn('Informe uma linha válida!');
      return;
    }

    if (passagem.empresa == null || passagem.empresa.length < 3) {
      window.scrollTo(0, 0);
      this.msgUtil.warn('Informe uma empresa válida!');
      return;
    }
    try {
      let valor = parseFloat(passagem.valor + '');
      if (!(valor > 0)) throw 'lower than zero';
      if (valor > 100) throw 'higher than one houndred';
      if (!(beneficio.valor > 0)) beneficio.valor = 0;
      beneficio.valor += valor;
    } catch (e) {
      window.scrollTo(0, 0);
      this.msgUtil.warn('O valor informado é invalido! Informe um número maior que zero e menor que 100.');
      return;
    }

    beneficio.idGrupoBeneficio = this.GRUPO_BENEFICIO.TRANSPORTE;
    if (beneficio.descricaoItens == null) beneficio.descricaoItens = '';
    let descricaoLinha = passagem.modo + ': ' + passagem.linha + ', ' + passagem.empresa + ', R$' + passagem.valor;
    let arrBeneficio = [];
    if (beneficio.descricaoItens != null && beneficio.descricaoItens.length > 0) {
      arrBeneficio = beneficio.descricaoItens.split(';#;');
    }
    arrBeneficio.push(descricaoLinha);
    beneficio.descricaoItens = arrBeneficio.join(';#;');
    this.formPassagem.reset();
    this.formPassagem.patchValue({ modo: 'ida', valor: (1).toFixed(2) });
    beneficioTransporte.patchValue(beneficio);
  }
  linhasBeneficioTransporte() {
    let dadosCandidato = this.form.get('dadosCandidato');
    let beneficioTransporte = dadosCandidato.get('beneficioTransporte');
    let beneficio = beneficioTransporte.value;
    if (beneficio.descricaoItens == null || beneficio.descricaoItens.length <= 0) return [];
    return beneficio.descricaoItens.split(';#;');
  }
  removerLinhasBeneficioTransporte(index) {
    let dadosCandidato = this.form.get('dadosCandidato');
    let beneficioTransporte = dadosCandidato.get('beneficioTransporte');
    let beneficio = beneficioTransporte.value;
    let arrLinhas = beneficio.descricaoItens.split(';#;');
    let removed = arrLinhas.splice(index, 1)[0];
    let value = removed.split(',')[2];
    value = parseFloat(value.substring(3));
    beneficio.valor -= value;
    beneficio.descricaoItens = arrLinhas.join(';#;');
    beneficioTransporte.patchValue(beneficio);
  }
  valorBeneficioTransporte() {
    let dadosCandidato = this.form.get('dadosCandidato');
    let beneficioTransporte = dadosCandidato.get('beneficioTransporte');
    let beneficio = beneficioTransporte.value;
    return beneficio.valor || 0;
  }
  padraoMoedaReal(val) {
    return 'R$' + val.toFixed(2);
  }
  necessitaValeTransporte() {
    return this.form.get('dadosCandidato').get('necessitaValeTransporte').value == true;
  }
  validarForm(): boolean {
    if (this.form.invalid) {
      FormUtil.markAllAsDirty(this.form);
      this.msgUtil.warn(this.lbs.m.formInvalido);
      return false;
    }
    if (this.necessitaValeTransporte() == true) {
      let beneficio = this.form.get('dadosCandidato').get('beneficioTransporte');
      let linhas = beneficio.get('descricaoItens').value;
      if (linhas == null || linhas.length <= 4) {
        window.scrollTo(0, 0);
        this.msgUtil.warn('Adicione ao menos uma (1) linha de transporte.');
        return false;
      }
    }
    return true;
  }

  //OPTIONS

  autoCompleteCidade(event): void {
    this.cidadeHttpService
      .autoCompleteGeneric(event.query)
      .subscribe((next: any[]) => (this.optionsCidade = next));
  }

  private _listarOptionsTipoContaBanco(data: { dataResolver: any }) {
    this.optionsTipoContaBanco = this.inputUtil.toSelectItem(
      data.dataResolver[DadosCadastraisResolverService.TIPOCONTABANCO],
      'label'
    );
  }

  private _listarOptionsTipoLogradouro(data: { dataResolver: any }) {
    this.optionsTipoLogradouro = this.inputUtil.toSelectItem(
      data.dataResolver[DadosCadastraisResolverService.TIPOLOGRADOURO],
      'label'
    );
  }

  private _listarOptionsTipoPcd(data: { dataResolver: any }) {
    this.optionsTipoPcd = this.inputUtil.toSelectItem(
      data.dataResolver[DadosCadastraisResolverService.TIPOPCD],
      'label'
    );
  }

  private _listarOptionsRacaCor(data: { dataResolver: any }) {
    this.optionsRacaCor = this.inputUtil.toSelectItem(
      data.dataResolver[DadosCadastraisResolverService.RACACOR],
      'label'
    );
  }

  private _listarOptionsEstadoCivil(data: { dataResolver: any }) {
    this.optionsEstadoCivil = this.inputUtil.toSelectItem(
      data.dataResolver[DadosCadastraisResolverService.ESTADOCIVIL],
      'label'
    );
  }

  private _listarOptionsGrauInstrucao(data: { dataResolver: any }) {
    this.optionsGrauInstrucao = this.inputUtil.toSelectItem(
      data.dataResolver[DadosCadastraisResolverService.GRAUINSTRUCAO],
      'label'
    );
  }

  private _listarOptionsEstado(data: { dataResolver: any }) {
    this.optionsEstado = this.inputUtil.toSelectItem(
      data.dataResolver[DadosCadastraisResolverService.ESTADO],
      'label'
    );
  }

  private _listarOptionsBanco(data: { dataResolver: any }) {
    this.optionsBanco = this.inputUtil.toSelectItem(
      data.dataResolver[DadosCadastraisResolverService.BANCO],
      'label'
    );
  }

  private _listarOptionsPais(data: { dataResolver: any }) {
    this.optionsPais = this.inputUtil.toSelectItem(
      data.dataResolver[DadosCadastraisResolverService.PAIS],
      'label'
    );
  }

  //OUTROS

  voltarParaMenu(): void {
    this.router.navigate(['../../menu'], { relativeTo: this.activatedRoute });
  }

  mostrarQualPdc(): boolean {
    const bean = this.form.value;
    return bean.pessoa.pcd == true;
  }

  selecionarPcd(event: any): void {
    //console.log(event);
    if (!event.value) {
      //console.log(this.form.controls['pessoa']);
      //console.log(this.form.get('pessoa.tipoPcd'));
      this.form.get('pessoa.tipoPcd').setValue(null);
    }
  }

  //LOCAL

  private _salvarDadosCadastrais(): void {
    sessionStorage.setItem('dadosCadastrais', JSON.stringify(this.form.value));

    if (this.validarForm()) {
      this.loadingService.on();
      //console.log(this.form.value);
      //sessionStorage.removeItem('dadosCadastrais');
      let bean = this.form.value;

      if (bean.pessoa.pcdObservacao)
        bean.pessoa.pcdObservacao = bean.pessoa.pcdObservacao.label || bean.pessoa.pcdObservacao;
      //console.log(bean);

      //console.log(bean);

      if (bean.dadosCandidato.necessitaValeTransporte == true) {
        bean.dadosCandidato.beneficioTransporte.idGrupoBeneficio = this.GRUPO_BENEFICIO.TRANSPORTE;
      } else {
        if (bean.dadosCandidato.beneficioTransporte != null) {
          bean.dadosCandidato.beneficioTransporte.excluir = true;
        }
      }
      this.candidatoVagaHttpService.salvarDadosCadastrais(bean).subscribe(
        (next: RetornoResponse) => {
          sessionStorage.removeItem('dadosCadastrais');
          this.router.navigate(['../../menu'], { relativeTo: this.activatedRoute });
          this.msgUtil.sucessPop(next.mensagem);
        },
        (error) => {
          this.msgUtil.error(error);
          this.loadingService.off();
        },
        () => {
          this.loadingService.off();
        }
      );
    }
  }

  private _validarFomularioPasso(passo: number): boolean {
    let valido: boolean = true;
    //console.log(valido);
    let message = this.lbs.m.formInvalido;

    //VAI CONTER UMA LISTA DE FORMCONTROLS REQUIRED NO PASSO INFORMADO.
    //SENDO O PRIMEIRO NOME O FORMGROUPNAME QUE PERTENCE E O SEGUNDO O FORMCONTROLNAME
    const fields = this.passosFormRequired[passo];
    fields.forEach((field: string) => {
      //console.log(field);
      if (this.form.get(field).invalid) {
        valido = valido ? false : valido;
        this.form.get(field).markAsTouched();
        this.form.get(field).markAsDirty();
      }

      if (field === 'pessoa.dataNascimento') {
        if (this.form.get(field).value) {
          //Convertendo valor preenchido no campo para Date
          const arrDataNascimento = this.form.get(field).value.split('/');
          const dataNascimento = new Date(arrDataNascimento[2], arrDataNascimento[1] - 1, arrDataNascimento[0]);
          const dataAtual = new Date();

          let idade = dataAtual.getFullYear() - dataNascimento.getFullYear();
          //Verifica se já fez aniversário no ano atual
          const mes_atual = dataAtual.getMonth() + 1;
          const dia_atual = dataAtual.getDate();
          const mes_aniversario = dataNascimento.getMonth() + 1;
          const dia_aniversario = dataNascimento.getDate();
          if (mes_atual < mes_aniversario || (mes_atual == mes_aniversario && dia_atual < dia_aniversario)) {
            idade = idade < 1 ? 0 : idade--;
          }

          if (idade < 14 || idade > 100) {
            valido = valido ? false : valido;
            this.form.get(field).markAsTouched();
            this.form.get(field).markAsDirty();
            message = 'Verifique a DATA DE NASCIMENTO, pois candidatos com menos de 14 anos não são permitidos.';
          }
        }
      }
    });
    if (valido) {
      this.form.markAsUntouched();
      this.form.markAsPristine();
    } else {
      this.msgUtil.warn(message);
    }
    return valido;
  }

  /**
   * WILL MONITOR VALUE CHANGES FROM CONTROLS, TRANSFORMING INPUT TEXT FIELDS TO UPPERCASE.
   */
  private _formValueChanges(control: any): void {
    if (control instanceof FormGroup) {
      const controls: any = (control as FormGroup).controls;
      Object.keys(controls).forEach((e) => {
        this._formValueChanges(controls[e]);
      });
    } else {
      control.valueChanges.subscribe((value) => {
        const isString = typeof value == 'string';
        if (isString && value != value.toUpperCase()) {
          control.setValue(value.toUpperCase());
        }
      });
    }
  }

  onlyNumber(event: KeyboardEvent) {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode < 48 || charCode > 57) {
      event.preventDefault();
    }
  }
  
}
