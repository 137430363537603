import { FormBuilder, FormGroup } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ConfirmationService, Message } from 'primeng/primeng';
import { ActivatedRoute, Router } from '@angular/router';
import { Labels } from 'src/app/core/constant/labels';
import { MessageGrowlService } from 'src/app/_util/message-growl/message-growl.service';
import { Mensagens } from 'src/app/core/constant/messages';
import { InputUtilService } from 'src/app/_util/input-util/input-util.service';
import { LoadingService } from 'src/app/_util/loading/loading.service';
import { AlterarEmailService } from '../../services/alterar-email.service';

@Component({
  selector: 'app-alterar-email',
  templateUrl: './alterar-email.component.html',
  styleUrls: ['./alterar-email.component.scss'],
})
export class AlterarEmailComponent implements OnInit {
  subscription: Subscription; //boa prática//aplicar em todos inscricoes, e fazer de uma forma mais facil com decorator como eh sugerido na WEB
  email: string = '';

  //optionsCentroCusto: SelectItem[];

  tempMsg: Message[] = [];

  //FORM
  classStyleColumn: string = 'p-col-12 p-xl-6 p-lg-6 p-md-6 p-sm-12 my-classStyleColumn p-nogutter';
  classStyleRow: string = 'p-col-12 p-grid';
  classStyleLabel: string = 'p-col-fixed my-classStyleLabel'; //'p-col-4 p-xl-4 p-lg-4 p-md-4 p-sm-12';//colunas com tamanhos não fixo (DEFAULT).
  classStyleField: string = 'p-col'; //'p-col-8 p-xl-8 p-lg-8 p-md-8 p-sm-12';//colunas com tamanhos não fixo (DEFAULT).

  constructor(
    private confirmationService: ConfirmationService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    //private location: Location,
    //public app: AppComponent,
    public lbs: Labels,
    private msgGrowl: MessageGrowlService,
    private msg: Mensagens,
    private alterarEmailService: AlterarEmailService,
    private loadingService: LoadingService
  ) {}
  ngOnDestroy() {
    if (this.subscription != null) this.subscription.unsubscribe; //boa prática
  }
  ngOnInit() {
    this.subscription = this.activatedRoute.data.subscribe((data: { resolve: any }) => {
      if (data.resolve != null) {
        this.email = data.resolve;
      }
    });
  }

  atualizarEmail() {
    if (this.email) {
      this.loadingService.on();
      this.alterarEmailService.atualizarEmail(this.email).subscribe(
        (res) => {
          this.mostrarMensagem(0, 'Email atualizado com sucesso!', true, true);
          this.loadingService.off();
          this.router.navigate(['']);
        },
        (err) => {
          const message = err.erro.mensagem || 'Erro ao atualizar e-mail.';
          this.mostrarMensagem(2, message, true, true);
          this.loadingService.off();
        }
      );
    } else {
      this.mostrarMensagem(2, `Favor preencha o campo e-mail.`, true, true);
    }
  }

  voltar() {
    history.back();
  }

  mostrarMensagem(severity: number, detail: string, limpar: boolean, mostrarFixa: boolean): void {
    const _severity: string =
      severity == 0 ? 'success' : severity == 1 ? 'info' : severity == 2 ? 'warn' : 'error';
    const summary: string =
      severity == 0
        ? this.msg.msg_success
        : severity == 1
        ? this.msg.msg_info
        : severity == 2
        ? this.msg.msg_warn
        : this.msg.msg_error;
    if (limpar) this.tempMsg = [];
    if (mostrarFixa) this.tempMsg.push({ severity: _severity, summary: summary, detail: detail });
    switch (severity) {
      case 0:
        this.msgGrowl.showSuccess(summary, detail);
        break;
      case 1:
        this.msgGrowl.showInfo(summary, detail);
        break;
      case 2:
        this.msgGrowl.showWarn(summary, detail);
        break;
      case 3:
        this.msgGrowl.showError(summary, detail);
        break;
      default:
        this.msgGrowl.showWarn(summary, detail);
        break;
    }
  }
}
