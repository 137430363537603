import { ParamUtilService } from 'src/app/_util/param-util/param-util.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AbstractGenericHttpService } from 'src/app/core/service/abstract-generic-http.service';
import { Path } from 'src/app/core/constant/path';

const PATH = Path.TBL + 'cidade/';
const URL_AUTOCOMPLETEGENERIC = PATH + 'generic-options-por-nome';

@Injectable({
  providedIn: 'root',
})
export class CidadeHttpService extends AbstractGenericHttpService {
  constructor(protected httpClient: HttpClient, private paramUtil: ParamUtilService) {
    super(httpClient);
  }

  autoCompleteGeneric(nome: string) {
    const p = this.paramUtil.jsonToParams({ nome: nome, maxResult: 25 });
    return this._doGetRetorno(URL_AUTOCOMPLETEGENERIC, p);
  }
}
