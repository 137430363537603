import { LoadingService } from 'src/app/_util/loading/loading.service';
import { ObjectUtil } from 'src/app/util/service/object-util.service';
import { forkJoin, Observable } from 'rxjs';
import { CandidatoVagaHttpService } from 'src/app/core/modulos/rh/http/candidato-vaga-http.service';
import { Resolve, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';

@Injectable()
export class DocumentosResolverService implements Resolve<any> {
  constructor(
    private candidatoVagaHttpService: CandidatoVagaHttpService,
    private loadingService: LoadingService
  ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    //this.loadingService.on();
    // let loadPile: Observable<any>[] = [];
    // loadPile.push(this.candidatoVagaHttpService.listarGenericOptions()); //0
    // return forkJoin(loadPile);
    const idVaga = Number.parseInt(sessionStorage.getItem('idVaga'));
    const ob0 = this.candidatoVagaHttpService.listarDocumentosVaga(idVaga);
    const ob1 = this.candidatoVagaHttpService.loadCpfSessao();
    return forkJoin([ob0, ob1]);
  }
}
