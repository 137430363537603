import { ObjectUtilService } from './../object-util/object-util.service';

import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { Message } from 'primeng/primeng';

@Injectable()
export class MessageGrowlService {
  //POSTERIORMENTE TENTAR A ABORDAGEM DE EventEmitter PARA VERIFICAR É MELHOR NA EMISSÃO DE EVENTOS PARA OUTROS COMPONENTES

  private subject: Subject<any> = new Subject<any>();
  messages: Message[] = [];

  constructor() {}

  getSubject(): Observable<any> {
    //método que retorna o Subject que outras classes vão se inscrever para observar as mudanças;
    return this.subject.asObservable();
  }

  showSuccess(mSummary: string, mDetail: string) {
    this.messages = [];
    this.messages.push({ severity: 'success', summary: mSummary, detail: mDetail });
    this.subject.next({ messages: this.messages });
  }

  showInfo(mSummary: string, mDetail: string) {
    this.messages = [];
    this.messages.push({ severity: 'info', summary: mSummary, detail: mDetail, life: 10000 });
    this.subject.next({ messages: this.messages });
  }

  showWarn(mSummary: string, mDetail: string | any) {
    //no caso de ser um erro desconhecido
    if (!(mDetail instanceof String) && typeof mDetail != 'string') {
      mDetail = mDetail.error != undefined ? mDetail.error : mDetail.statusMessage;
    }
    if (ObjectUtilService.isBlank(mSummary) && ObjectUtilService.isBlank(mDetail))
      mSummary = 'Não foi possível completar está ação.';
    if (mDetail == undefined) mDetail = 'desconhecido';
    this.messages = [];
    this.messages.push({ severity: 'warn', summary: mSummary, detail: mDetail, life: 10000 });
    this.subject.next({ messages: this.messages });
  }

  showError(mSummary: string, mDetail: string) {
    this.messages = [];
    this.messages.push({ severity: 'error', summary: mSummary, detail: mDetail, life: 10000 });
    this.subject.next({ messages: this.messages });
  }

  showMultiple(messages: string[]) {
    this.messages = [];
    messages.forEach((message: string) => {
      this.messages.push({ severity: 'info', summary: message[0], detail: message[1], life: 10000 });
    });
  }

  clear() {
    this.messages = [];
    this.subject.next({ messages: this.messages });
  }
}
