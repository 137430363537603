import { DoCheck, IterableDiffers, Sanitizer } from '@angular/core';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Message } from 'primeng/api';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { EstadoConservacao } from 'src/app/m_estoque/model/estado-conservacao';
import { Inventario } from 'src/app/m_estoque/model/inventario';
import { ItemInventario } from 'src/app/m_estoque/model/item-inventario';
import { Material } from 'src/app/m_estoque/model/material';
import { InventarioService } from 'src/app/m_estoque/services/inventario.service';
import { MaterialService } from 'src/app/m_estoque/services/material.service';
import { Labels } from 'src/app/core/constant/labels';
import { LoadingService } from 'src/app/_util/loading/loading.service';
import { MessageGrowlService } from 'src/app/_util/message-growl/message-growl.service';
import { callbackify } from 'util';
import { InventarioLoadService } from '../resolver/inventario-load.service';
import { Mensagens } from 'src/app/core/constant/messages';

@Component({
  selector: 'app-inventario-edit',
  templateUrl: './inventario-edit.component.html',
  styleUrls: ['./inventario-edit.component.scss'],
})
export class InventarioEditComponent implements OnInit, OnDestroy, DoCheck {
  form: FormGroup;
  configCampos: any = {};
  bean: Inventario = new Inventario(null);
  itemEdicao: ItemInventario = new ItemInventario(null);
  itemSelecionado: ItemInventario;
  itens: ItemInventario[] = [];
  subscription: Subscription;
  tempMsg: Message[] = [];
  estadosConservacao: EstadoConservacao[] = [];
  filteredMateriais: Material[] = [];
  esconderForm = false;
  exibirOpcoes = false;
  excluirInventario = false;
  excluirItem = false;
  cacheId = {};
  differ;
  loading: any = {
    on: () => this.loadingService.onLoading(true),
    off: () => this.loadingService.onLoading(false),
  };
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private loadingService: LoadingService,
    public lbs: Labels,
    private msgGrowl: MessageGrowlService,
    private msg: Mensagens,
    private formBuilder: FormBuilder,
    private inventarioService: InventarioService,
    private materialService: MaterialService,
    private differs: IterableDiffers,
    private sanitization: DomSanitizer
  ) {
    this.differ = differs.find([]).create(null);
  }
  ngOnDestroy() {
    if (this.subscription != undefined) this.subscription.unsubscribe();
  }
  ngOnInit() {
    this.load();
    for (let input of Array.from(document.getElementsByClassName('input'))) {
      input.setAttribute('autocomplete', 'off');
    }
  }
  ngDoCheck() {
    const change = this.differ.diff(this.itens);
    if (change != undefined && change != null) {
      for (let item of this.itens) {
        let url = item._urlAnexo;
        if (item.anexo + '' != 'undefined' && (this.cacheId[url] == undefined || this.cacheId[url] == null)) {
          this.inventarioService.imagemItem(url).subscribe((file) => {
            var reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend = () => {
              var base64data = reader.result;
              this.cacheId[url] = this.sanitization.bypassSecurityTrustStyle('url(' + base64data + ')');
            };
          });
        }
      }
    }
  }
  load() {
    this.carregarForm();
    this.loading.on();
    this.subscription = this.activatedRoute.data.subscribe(
      (next) => {
        this.loading.off();
        this.estadosConservacao = next.loadResolver[InventarioLoadService.ESTADO_CONSERVACAO];
        if (this.router.url.includes('/edit')) {
          this.bean = next.loadResolver[InventarioLoadService.INVENTARIO].retorno;
          this.itens = next.loadResolver[InventarioLoadService.ITENS_INVENTARIO].map((i) => new ItemInventario(i));
          // this.esconderForm = true;
        }
      },
      (error) => {
        if (error.error) error = error.error;
        this.mostrarMensagem(3, error.mensagem || error, true, true);
        this.loading.off();
      },
      () => {
        this.loading.off();
      }
    );
  }
  voltar() {
    this.limpar();
    this.router.navigate(['list'], { relativeTo: this.activatedRoute.parent });
  }
  salvar() {
    this.loading.on();
    this.subscription = this.inventarioService.salvar(this.bean).subscribe(
      (next) => {
        let loadPile: Observable<any>[] = [];
        this.bean = new Inventario(next.retorno);
        this.salvarItens().subscribe((results) => {
          let itens = results.map((data: { retorno: any }) => new ItemInventario(data.retorno));
          this.itens = itens;
          this.loading.off();
          this.router.navigate(['edit', this.bean.id], {
            relativeTo: this.activatedRoute.parent,
          });
        });
      },
      (error) => {
        if (error.error) error = error.error;
        this.mostrarMensagem(3, error.mensagem || error, true, true);
        this.loading.off();
      },
      () => {
        this.loading.off();
      }
    );
  }
  excluir() {
    this.loading.on();
    this.subscription = this.inventarioService.excluir(this.bean.id).subscribe(
      (next) => {
        this.loading.off();
        this.router.navigate(['list'], {
          relativeTo: this.activatedRoute.parent,
        });
      },
      (error) => {
        if (error.error) error = error.error;
        this.mostrarMensagem(3, error.mensagem || error, true, true);
        this.loading.off();
      },
      () => {
        this.loading.off();
      }
    );
  }
  salvarItens(): Observable<any> {
    let loadPile: Observable<any>[] = [];
    for (let item of this.itens) {
      loadPile.push(this.inventarioService.salvarItem(this.bean, item));
    }
    return forkJoin(loadPile);
  }
  novo() {
    this.limpar();
    this.router.navigate(['new'], { relativeTo: this.activatedRoute.parent });
  }

  carregarForm() {
    this.form = this.formBuilder.group({
      id: [null, [Validators.maxLength(10)]],
      material: [null, [Validators.required]],
      estadoConservacao: [null, [Validators.required]],
      codigoPatrimonio: [null, []],
      observacaoFuncionario: [null, []],
      uploadAnexo: [null, []],
    });
    this.carregarConfig();
  }
  carregarConfig() {
    //@patricia-temp: removi o private para não dar erro, pois foi adicionado a interface, que não aceita private :/
    this.configCampos = {
      btnExcluir: this.bean.id > 0,
    };
  }
  limpar(): void {
    this.bean = new Inventario(null);
    this.itens = [];
    this.itemEdicao = undefined;
    this.itemSelecionado = undefined;
    this.limparItem();
  }

  adicionarItem() {
    if (this.validarItem()) {
      Object.assign(this.itemEdicao, new ItemInventario(this.form.value));
      let itens = [];
      let isIn: boolean = false;
      if (this.itemEdicao.id != undefined || this.itemEdicao._id != undefined) {
        for (let i = 0; i < this.itens.length; i++) {
          if (this.itens[i].id == this.itemEdicao.id || this.itens[i]._id == this.itemEdicao._id) {
            this.itens[i] = new ItemInventario(this.itemEdicao);
          }
        }
      } else {
        let item = new ItemInventario(this.itemEdicao);
        item._id = this.itens.length + '-' + Math.ceil(Math.random() * 10000);
        this.itens.push(item);
      }
      this.limparItem();
      this.salvar();
    }
  }

  limparItem(): void {
    this.itemEdicao = new ItemInventario(null);
    Object.keys(this.form.controls).forEach((e) => {
      this.form.controls[e].markAsPristine();
      this.form.controls[e].markAsUntouched();
    });
    this.form.reset();
    this.form.patchValue(this.itemEdicao);
    // if (this.router.url.includes("/edit")) this.esconderForm = true;
  }

  selecionarItem(item) {
    this.itemSelecionado = new ItemInventario(item);
    this.exibirOpcoes = true;
  }
  mensagemExcluirItem(item) {
    this.itemSelecionado = new ItemInventario(item);
    this.excluirItem = true;
  }

  editarItemSelecionado() {
    this.limparItem();
    this.itemEdicao = new ItemInventario(this.itemSelecionado);
    this.itemSelecionado = undefined;
    this.exibirOpcoes = false;
    this.esconderForm = false;
    this.form.patchValue(this.itemEdicao);
  }
  excluirItemSelecionado() {
    this.loading.on();
    this.exibirOpcoes = false;
    this.excluirItem = false;
    if (this.itemSelecionado.id > 0) {
      this.inventarioService.excluirItem(this.bean, this.itemSelecionado).subscribe(
        (next) => {
          this.inventarioService.itens(this.bean).subscribe((next) => {
            this.itens = next.map((data: any) => new ItemInventario(data));
            this.itemSelecionado = undefined;
            this.loading.off();
          });
        },
        (error) => {
          if (error.error) error = error.error;
          this.mostrarMensagem(3, error.mensagem || error, true, true);
          this.loading.off();
        },
        () => {
          this.loading.off();
        }
      );
    } else {
      for (let i = 0; i < this.itens.length; i++) {
        if (this.itens[i]._id == this.itemSelecionado._id) {
          this.itens.splice(i, 1);
          this.loading.off();
          break;
        }
      }
    }
  }
  // urlImagemItem(item: ItemInventario) {
  //   if (item.uploadAnexo != undefined) return item.uploadAnexo;
  //   let url: string | ArrayBuffer = this.inventarioService.urlImagemItem(item);
  //   return url;
  // }

  // loadImagemItem(item: ItemInventario, id: string) {
  //   if (item.uploadAnexo != undefined) return undefined;
  //   let img = this.urlImagemItem(item);
  //   if (img != undefined) {
  //     if (this.cacheId[img.toString()] == undefined) {
  //       this.inventarioService.imagemItem(img.toString()).subscribe((file) => {
  //         var reader = new FileReader();
  //         reader.readAsDataURL(file);
  //         reader.onloadend = () => {
  //           var base64data = reader.result;
  //           this.cacheId[img.toString()] = "url(" + base64data + ")";
  //           document.getElementById(id).style.backgroundImage =
  //             this.cacheId[img.toString()];
  //         };
  //       });
  //     } else {
  //       document.getElementById(id).style.backgroundImage =
  //         this.cacheId[img.toString()];
  //     }
  //   }
  //   return img;
  // }
  filterMateriais(event) {
    let query: string = event.query.trim();
    this.materialService.buscarPorNome(query).subscribe((next) => {
      this.filteredMateriais = next;
    });
  }
  clickUpload() {
    document.getElementById('botaoFoto').click();
  }
  onFotoSelecionada(botaoUpload, event) {
    let file: File;
    if (event.files != undefined) {
      file = event.files[0];
    }
    if (event.target != undefined && event.target.files != undefined) {
      file = event.target.files[0];
    }
    let reader = new FileReader();
    reader.onloadend = () => {
      this.resizeImage(file, 800, 800, (name, result?) => {
        this.itemEdicao.uploadAnexo = result || reader.result;
        this.itemEdicao._anexoNome = name || file.name;
      });
    };
    reader.readAsDataURL(file);
    botaoUpload.value = '';
  }
  resizeImage(
    file: File,
    maxWidth: number,
    maxHeight: number,
    callback: (name: string, result?: string | ArrayBuffer) => void
  ) {
    let image = new Image();
    image.src = URL.createObjectURL(file);
    image.onload = () => {
      let width = image.width;
      let height = image.height;
      if (width <= maxWidth && height <= maxHeight) {
        callback(file.name);
      }
      let newWidth;
      let newHeight;
      if (width > height) {
        newHeight = height * (maxWidth / width);
        newWidth = maxWidth;
      } else {
        newWidth = width * (maxHeight / height);
        newHeight = maxHeight;
      }
      let canvas = document.createElement('canvas');
      canvas.width = newWidth;
      canvas.height = newHeight;
      let context = canvas.getContext('2d');
      context.drawImage(image, 0, 0, newWidth, newHeight);
      var finalFile = canvas.toDataURL(file.type);
      let reader2 = new FileReader();
      canvas.toBlob(function (blob) {
        reader2.readAsDataURL(blob);
      }, file.type);
      reader2.onloadend = () => {
        callback(file.name, reader2.result);
      };
    };
  }
  validarItem(): boolean {
    //pristine é o contrário do dirty
    let valido: boolean = true;
    /**Verificar se os campos obrigatorios estao preenchidos */
    Object.keys(this.form.controls).forEach((e) => {
      if (this.form.controls[e].invalid) {
        /**vai setar o campo como ng-dirty e como ja sera ng-invalid aplicara o css do template primeng invalido ng-dirty ng-invalid*/
        this.form.controls[e].markAsDirty();
        valido = false;
      }
    });
    if (this.itemEdicao.anexo == undefined && this.itemEdicao.uploadAnexo == undefined) {
      if (valido) {
        this.mostrarMensagem(2, this.lbs.m.anexoObrigatorio, true, true);
        return (valido = false);
      }
      valido = false;
    }
    /**Ao final verifica se após verificações formulário e dados estão válidos*/
    if (!valido || !this.form.valid) {
      valido = false;
      this.mostrarMensagem(2, this.lbs.m.formVazio, true, true);
    }
    return valido;
  }
  mostrarMensagem(severity: number, detail: string, limpar: boolean, mostrarFixa: boolean): void {
    const _severity: string =
      severity == 0 ? 'success' : severity == 1 ? 'info' : severity == 2 ? 'warn' : 'error';
    const summary: string =
      severity == 0
        ? this.msg.msg_success
        : severity == 1
        ? this.msg.msg_info
        : severity == 2
        ? this.msg.msg_warn
        : this.msg.msg_error;
    if (limpar) this.tempMsg = [];
    if (mostrarFixa)
      this.tempMsg.push({
        severity: _severity,
        summary: summary,
        detail: detail,
      });
    switch (severity) {
      case 0:
        this.msgGrowl.showSuccess(summary, detail);
        break;
      case 1:
        this.msgGrowl.showInfo(summary, detail);
        break;
      case 2:
        this.msgGrowl.showWarn(summary, detail);
        break;
      case 3:
        this.msgGrowl.showError(summary, detail);
        break;
      default:
        this.msgGrowl.showWarn(summary, detail);
        break;
    }
  }
}
