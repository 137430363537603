import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ConfigCliente } from 'src/app/_config-cliente/config-cliente';
import { ParamUtilService } from 'src/app/_util/param-util/param-util.service';
import { EstadoConservacao } from '../model/estado-conservacao';

const MODULO = 'estoque';
const CLASSE = '/estado-conservacao';
const PATH_CLASSE = ConfigCliente.APP_REST() + MODULO + CLASSE;
const LISTAR_LITE = PATH_CLASSE + '/listar-lite';
@Injectable({
  providedIn: 'root',
})
export class EstadoConservacaoService {
  constructor(private httpClient: HttpClient, private paramUtilService: ParamUtilService) {}

  listar(): Observable<EstadoConservacao[]> {
    return this.httpClient.get<EstadoConservacao[]>(LISTAR_LITE);
  }
}
