import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ConfigCliente } from 'src/app/_config-cliente/config-cliente';
import { ParamUtilService } from 'src/app/_util/param-util/param-util.service';
import { Material } from '../model/material';

const MODULO = 'estoque/';
const CLASSE = 'material/';
const PATH_CLASSE = ConfigCliente.APP_REST() + MODULO + CLASSE;
@Injectable({
  providedIn: 'root',
})
export class MaterialService {
  constructor(private httpClient: HttpClient, private paramUtilService: ParamUtilService) {}

  buscarPorNome(termo: string, maxResult?: number): Observable<Material[]> {
    return this.httpClient.get<Material[]>(PATH_CLASSE + 'buscar-por-nome', {
      params: { nome: termo, maxResult: (maxResult > 0 ? maxResult : 25) + '' },
    });
  }
}
