import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ConfirmationService } from 'primeng/api';
import { CalendarModule } from 'primeng/calendar';
import { CheckboxModule } from 'primeng/checkbox';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { FileUploadModule } from 'primeng/fileupload';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { KeyFilterModule } from 'primeng/keyfilter';
import { MessagesModule } from 'primeng/messages';
import { PanelModule } from 'primeng/panel';
import { SelectButtonModule } from 'primeng/selectbutton';
import { TableModule } from 'primeng/table';
import { TabViewModule } from 'primeng/tabview';
import { ToolbarModule } from 'primeng/toolbar';

import { MessageModule } from 'primeng/primeng';
import { PaginasRhRoutes } from '../../paginas-rh.routes';
import { RhAlterarSenhaComponent } from './rh-alterar-senha.component';
@NgModule({
  imports: [
    CommonModule,
    //BrowserModule, -- ser importado apenas uma vez no appModule,
    ReactiveFormsModule,
    FormsModule,
    PaginasRhRoutes,
    /*PRIMENG*/
    ConfirmDialogModule,
    InputTextModule,
    KeyFilterModule,
    MessagesModule,
    MessageModule,
    PanelModule,
    TabViewModule,
    TableModule,
    ToolbarModule,
    CalendarModule,
    InputTextareaModule,
    SelectButtonModule,
    InputSwitchModule,
    CheckboxModule,
    FileUploadModule,
  ],
  declarations: [RhAlterarSenhaComponent],
  providers: [
    /*PRIMENG*/
    ConfirmationService,
    //MessageService
  ],
})
export class RhAlterarSenhaModule {}
