import { MessageGrowlService } from 'src/app/_util/message-growl/message-growl.service';
import { Labels } from 'src/app/core/constant/labels';
import { OnInit } from '@angular/core';
import { ObjectUtil } from './object-util.service';
import { Message } from 'primeng/api';
import { Injectable } from '@angular/core';

const S_SUCCESS = 0;
const S_INFO = 1;
const S_WARN = 2;
const S_ERROR = 3;
@Injectable({
  providedIn: 'root',
})
export class MessageUtilService {
  public tempMsg: Message[] = [];

  constructor(private msgGrowl: MessageGrowlService, private msg: Labels) {}

  /**MOSTRAR MENSAGEM DO TIPO ERROR SOMENTE FIXA COM LIMPAR=TRUE, MOSTRARFIXO=TRUE, MOSTRARMOVEL=FALSE */
  error(mensagem: string | any): void {
    this.mostrarMensagem(S_ERROR, mensagem, true, true, false);
  }
  /**MOSTRAR MENSAGEM DO TIPO ERROR SOMENTE MÓVEL COM LIMPAR=TRUE, MOSTRARFIXO=FALSE, MOSTRARMOVEL=TRUE */
  errorPop(mensagem: string | any): void {
    this.mostrarMensagem(S_ERROR, mensagem, true, false, true);
  }

  /**MOSTRAR MENSAGEM DO TIPO WARN SOMENTE FIXA COM LIMPAR=TRUE, MOSTRARFIXO=TRUE, MOSTRARMOVEL=FALSE */
  warn(mensagem: string | any): void {
    this.mostrarMensagem(S_WARN, mensagem, true, true, false);
  }
  /**MOSTRAR MENSAGEM DO TIPO WARN SOMENTE MÓVEL COM LIMPAR=TRUE, MOSTRARFIXO=FALSE, MOSTRARMOVEL=TRUE */
  warnPop(mensagem: string | any): void {
    this.mostrarMensagem(S_WARN, mensagem, true, false, true);
  }

  /**MOSTRAR MENSAGEM DO TIPO SUCCESS SOMENTE FIXA COM LIMPAR(DEFAULT)=TRUE, MOSTRARFIXO=TRUE, MOSTRARMOVEL=FALSE */
  sucess(mensagem: string | any, limpar?: boolean): void {
    limpar;
    this.mostrarMensagem(S_SUCCESS, mensagem, true, true, false);
  }

  sucessPop(mensagem: string | any, limpar?: boolean): void {
    this.mostrarMensagem(S_SUCCESS, mensagem, true, false, true);
  }

  /**MOSTRAR MENSAGEM DO TIPO INFO SOMENTE FIXA COM LIMPAR(DEFAULT)=TRUE, MOSTRARFIXO=TRUE, MOSTRARMOVEL=FALSE */
  info(mensagem: string | any, limpar?: boolean): void {
    limpar;
    this.mostrarMensagem(S_INFO, mensagem, true, true, false);
  }

  infoPop(mensagem: string | any, limpar?: boolean): void {
    this.mostrarMensagem(S_INFO, mensagem, true, false, true);
  }

  /**
   * @param severity 0-sucess, 1-info, 2-warn, 3-error
   * @param detail mensagem a ser mostrada (pode ser uma string ou objeto error do exception)
   * @param limpar indica se as mensagens anteriores serão limpas
   * @param mostrarFixa indica se vai mostrar a mensagem do tipo fixa
   * @param mostrarMovel indica se vai mostrar a mensagem do tipo popup
   */
  mostrarMensagem(
    severity: number,
    detail: string | any,
    limpar: boolean,
    mostrarFixa: boolean,
    mostrarMovel?: boolean
  ): void {
    const _severity: string =
      severity == 0 ? 'success' : severity == 1 ? 'info' : severity == 2 ? 'warn' : 'error';
    const summary: string =
      severity == 0
        ? this.msg.msg_success
        : severity == 1
        ? this.msg.msg_info
        : severity == 2
        ? this.msg.msg_warn
        : this.msg.msg_error;
    if (limpar) this.tempMsg = [];

    if (!ObjectUtil.isString(detail)) {
      //!(detail instanceof String) && typeof detail != 'string'){
      detail = detail.error != undefined ? detail.error : detail.statusMessage;
      detail = !ObjectUtil.isString(detail) ? detail.error || 'Falha ao excecutar esta ação.' : detail;
      console.log(detail);
      detail = detail.length > 250 ? 'Falha ao excecutar esta ação. (2)' : detail;
    }

    if (mostrarFixa) {
      //if(detail.error != undefined && detail.error.codigo != undefined) detail = detail.error.mensagem;
      this.tempMsg.push({ severity: _severity, summary: summary, detail: detail });
    }

    //mostrarMovel = mostrarMovel == null ? true : false;
    mostrarMovel = mostrarMovel == null ? true : mostrarMovel;

    if (mostrarMovel) {
      switch (severity) {
        case 0:
          this.msgGrowl.showSuccess(summary, detail);
          break;
        case 1:
          this.msgGrowl.showInfo(summary, detail);
          break;
        case 2:
          this.msgGrowl.showWarn(summary, detail);
          break;
        case 3:
          this.msgGrowl.showError(summary, detail);
          break;
        default:
          this.msgGrowl.showWarn(summary, detail);
          break;
      }
    }
  }

  /**Limpar a lista de mensagens fixas e popup do primeng */
  limpar(): void {
    this.tempMsg = [];
    this.msgGrowl.clear();
  }
}
