import { Subject, Observable } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LoadingService {
  //OBSERVABLE -> STRING SOURCE (DADO QUE SERÁ TRANSMITIDO)
  private loading: Subject<boolean> = new Subject<boolean>();
  //OBSERVABLE -> STRING STREAMS (QUEM FARÁ A TRANSMISSÃO)
  loading$: Observable<boolean> = this.loading.asObservable();

  constructor() {}

  //FALSE - ESCONDE O MODAL LOAGIND, E TRUE - MOSTRA O MODAL LOADING
  onLoading(loading: boolean) {
    //alert('LOADING: ' + loading)
    this.loading.next(loading);
  }

  /**MOSTRA O MODAL LOADING*/
  on() {
    this.loading.next(true);
  }

  /**ESCONDE O MODAL LOAGIND*/
  off() {
    this.loading.next(false);
  }
}
