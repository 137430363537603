import { Injectable } from '@angular/core';

@Injectable()
export class ObjectUtilService {

  constructor() { }

  /**Verificar se o valor é NULL, UNDEFINED, 'null', '' (ou vazio)*/
  static isBlank(valor: any): boolean{
    return (valor == null ? true : (valor == undefined ? true : (valor == 'null' ? true : (valor.toString().trim() == '' ? true : false))));
  }
  static isNotBlank(valor: any): boolean{
    return !this.isBlank(valor);
  }

  /**Verifica se o objeto não é NULL, ou UNDEFINED */
  static isNotNull(objeto: any): boolean{
    return objeto == null ? false : (objeto == undefined ? false : true);
  }
  
  /**Verificar se o valor é NULL, UNDEFINED, 'null', '' (ou vazio)*/
  static isNull(objeto: any): boolean{
    return !this.isNotBlank;
  }

  /**Verifica se o ID do objeto é NULL*/
  static isIdNull(objeto: any): boolean{
    return objeto == null ? true : (objeto.id == null ? true : false);
  }
  static isIdNotNull(objeto: any): boolean{
    return !this.isIdNull(objeto);
  }

  /**Verifica se o campo VALOR é igual a algum item presenta na LISTA, se sim retorna TRUE indicando que a lista contem o VALOR, se não retorna FALSE */
  static contains(valor: any, lista: Array<any>): boolean{
    for (let l of lista) {
      if(valor == l) return true;
    }
    return false;
  }
  
  /**Verifica se o campo VALOR é igual a algum item presenta na LISTA, se sim retorna TRUE indicando que a lista contem o VALOR, se não retorna FALSE */
  static notContains(valor: any, lista: Array<any>): boolean{
    return !this.contains(valor, lista);
  }

  static defaultValue(valor: any, defaultValue: any): any{
    return this.isNotNull(valor) ? valor : defaultValue;
  }

  /**Verifica se o array é nulo ou vazio*/
  static isEmpty(lista: any[]): boolean{
    return lista == null ? true : (lista.length == 0 ? true : false);
  }

  /**Verifica se o array não é nulo nem vazio*/
  static isNotEmpty(lista: any[]): boolean{
    return !this.isEmpty(lista);
  }

  /*VALIDAÇÃO*/

  static isEmailValido(email: string){
    if(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email))
      return (true)
    else
      return (false)
  }

}
