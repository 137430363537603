import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { LazyLoadEvent, ConfirmationService } from 'primeng/api';

import { Labels } from 'src/app/core/constant/labels';
import { LancamentoService } from 'src/app/m_rh/services/lancamento.service';
import { LancamentoList } from 'src/app/m_rh/model/lancamento-list';
import { MessageGrowlService } from 'src/app/_util/message-growl/message-growl.service';
import { Mensagens } from 'src/app/core/constant/messages';

@Component({
  selector: 'app-lancamento-list',
  templateUrl: './lancamento-list.component.html',
  styleUrls: ['./lancamento-list.component.scss'],
})
export class LancamentoListComponent implements OnInit {
  lazyLoadEvent: LazyLoadEvent;
  size: number;
  lista: LancamentoList[];
  itemSelecionado: LancamentoList;

  constructor(
    public lbs: Labels,
    private lancamentoService: LancamentoService,
    private msgGrowl: MessageGrowlService,
    private msg: Mensagens,
    private router: Router
  ) {}

  ngOnInit() {
    this.listar();
  }

  listar(): void {
    this.lancamentoService.ListarPorFuncionario().subscribe(
      (next) => {
        this.lista = next;
      },
      (error) => {
        this.msgGrowl.showWarn(this.msg.msg_warn, error);
        //this.app.unblock();
      },
      () => {
        //this.app.unblock();
      }
    );
  }

  listarLazy(event: LazyLoadEvent): void {
    //this.app.block();
    this.lazyLoadEvent = event;

    this.lancamentoService.listarLazy().subscribe(
      (next) => {
        this.lista = next[0];
        this.size = next[1];
      },
      (error) => {
        this.msgGrowl.showWarn(this.msg.msg_warn, error);
        //this.app.unblock();
      },
      () => {
        //this.app.unblock();
      }
    );
  }

  editar(bean: LancamentoList): void {
    console.log('editar: ' + 1);
    this.router.navigate(['/rh/lancamento/edit', bean.id, Math.random().toString(36).substring(7)]);
    console.log('editar: ' + 2);
  }

  novo(): void {
    console.log('editar: ' + 1);
    this.router.navigate(['/rh/lancamento/new']);
    console.log('editar: ' + 2);
  }

  cssTipo(idTipoLancamento, idSituacao) {
    var retorno = '';
    if (idTipoLancamento == 1) {
      //hora extra
      retorno = 'far fa-clock';
    } else if (idTipoLancamento == 2) {
      //banco de horas
      retorno = 'fas fa-hourglass-half';
    } else {
      //sobre aviso
      retorno = 'fas fa-exclamation-circle';
    }
    if (idSituacao == 1) {
      retorno += ' iconeAprovado';
    } else {
      retorno += ' iconeReprovado';
    }
    return retorno;
  }
}
